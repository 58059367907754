import { Button } from "@appkit4/react-components";
import { EdgeType, Instance } from "../../@types/enums";
import { getEnumNumberKeys } from "../../assets/helpers/helpers";
import {
  getLinkColorBasedOnLinktype,
  getNodeColorBasedOnGroup,
} from "../graph/Graph";
import { useEffect, useState } from "react";

const legendStateLocalStoreName = "isLegendHidden"

function GraphLegend() {
  const [isLegendHidden, setLegendHidden] = useState<boolean>(true);

  useEffect(() => {
    setLegendHidden(getLegendHiddenStateFromLocalStore())
  },[])

  function getLegendHiddenStateFromLocalStore():boolean {
    const isLegendHiddenString = localStorage.getItem(legendStateLocalStoreName)
    if(isLegendHiddenString){
      return isLegendHiddenString === "true" ? true : false 
    }
    return false
  }

  function onClickShowLegend(){
    localStorage.setItem(legendStateLocalStoreName, (!isLegendHidden).toString())
    setLegendHidden(!isLegendHidden)
  }

  function renderNodeTypes() {
    const instances = getEnumNumberKeys(Instance).filter(
      (v) => Instance[v] !== "undefined"
    );
    return instances.map((v, i) => (
      <LegendItem
        key={i}
        color={getNodeColorBasedOnGroup(v)}
        description={getInstanceDescriptions(v.toString())}
        isArrow={false}
      />
    ));
  }

  function getInstanceDescriptions(instance: string) {
    const inctanceDescriptions = new Map([
      [Instance.KorkeinHallintoOikeus.toString(), "Korkein hallinto-oikeus"],
      [Instance.Keskusverolautakunta.toString(), "Keskusverolautakunta"],
      [Instance.HallintoOikeus.toString(), "Hallinto-oikeus"],
      [Instance.EUTuomioistuin.toString(), "EU-tuomioistuin"],
      [Instance.Verohallinto.toString(), "Verohallinto"],
      [Instance.Oikaisulautakunta.toString(), "Oikaisulautakunta"],
      [Instance.Muu.toString(), "Muu"],
    ]);

    return inctanceDescriptions.get(instance) ?? "";
  }

  function renderArrowTypes() {
    const arrows = getEnumNumberKeys(EdgeType).filter(
      (v) =>
        EdgeType[v] === EdgeType[EdgeType.referencedIn] ||
        EdgeType[v] === EdgeType[EdgeType.manualConnection] ||
        EdgeType[v] === EdgeType[EdgeType.selectedEdge]
    );
    return arrows.map((v, i) => (
      <LegendItem
        key={i}
        color={getLinkColorBasedOnLinktype(v)}
        description={getArrowDescriptions(v.toString())}
        isArrow={true}
      />
    ));
  }

  function getArrowDescriptions(arrow: string) {
    const arrowDescription = new Map([
      [EdgeType.referencedIn.toString(), "Päätöstekstistä tunnistetut viittaukset"],
      [EdgeType.manualConnection.toString(), "Käyttäjien lisäämät viittaukset"],
      [EdgeType.selectedEdge.toString(), "Valittu viittaus"],
    ]);

    return arrowDescription.get(arrow) ?? "";
  }

  return (
    <div
      className={`graph-legend-content ${
        isLegendHidden ? "graph-legend-hidden" : "graph-legend-show"
      }`}
    >
      <div className="graph-legend-header">
        <h4>Selite</h4>
        <div className={`graph-legend-button ${isLegendHidden ? "graph-legend-button-popout" : ""}`}>
          <Button
            add
            compact
            icon={`icon-${isLegendHidden ? "help-question-outline ap-font-22" : "left-chevron-fill"}`}
            onClick={onClickShowLegend}
          ></Button>
        </div>
      </div>
      <div className="graph-legend-items">{renderNodeTypes()}</div>
      <div className="graph-legend-items">{renderArrowTypes()}</div>
    </div>
  );
}

export default GraphLegend;

type LegendItemProps = {
  key: number;
  color: string;
  description: string;
  isArrow: boolean;
};
function LegendItem({ color, description, isArrow }: LegendItemProps) {
  return (
    <div className="graph-legend-item">
      {isArrow ? (
        <span
          className="Appkit4-icon icon-arrow-up-bold-fill graph-legend-arrow"
          style={{ color: color }}
        />
      ) : (
        <span
          className="graph-legend-dot"
          style={{ backgroundColor: color }}
        ></span>
      )}
      <span>{description}</span>
    </div>
  );
}
