import { Loading } from "@appkit4/react-components";
import React from "react";

export default function Spinner({loadingText}:{loadingText?:string}) {
  return (
    <div className="center-content" style={{flexDirection: "column"}}>
      <Loading loadingType="circular" indeterminate={true} compact={false} />
      {loadingText ? <span>{loadingText}</span> : <></>}
    </div>
  );
}