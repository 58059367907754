import { Search } from "@appkit4/react-components";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StoreContext } from "../../App";
import { isEmptyOrSpaces } from "../../assets/helpers/helpers";
import { IRootStore } from "../../store/RootStore";

const SearchInput = observer(() => {
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const store = useContext<IRootStore>(StoreContext).courtCaseStore;

  function handleValueSelcted(value: string) {
    setSearchParams({name: value})
    store.getOneCourtCaseAndRelations(value);
  
  }

  function handleEmptyField(event: React.KeyboardEvent<HTMLInputElement>){
    if(isEmpty && event.code === "Enter"){
      store.rootStore.filterStore.setCourtCaseSearchValue(undefined);
      store.getAllCourtCasesAndRelations();
      if(searchParams.has("name")){
        const newParams = searchParams
        newParams.delete("name")
        setSearchParams(newParams)
      }
    }
  }

  function handleOnClear(value: any){
    store.getAllCourtCasesAndRelations()
    if(searchParams.has("name")){
      const newParams = searchParams
      newParams.delete("name")
      setSearchParams(newParams)
    }
  }

  function handleOnChange (value:string){
    setIsEmpty(isEmptyOrSpaces(value))
    store.rootStore.filterStore.setCourtCaseSearchValue(value);
  }

  return (
    <div style={{ position: "relative", width: "320px", height: "50px" }}>
      <Search
        data={store.rootStore.filterStore.courtCaseProperties.titles.map((v) => ({
          value: v,
          label: v,
        }))}
        noResultFound={<>Ei hakutuloksia</>}
        searchValue={store.rootStore.filterStore.courtCaseSearch ? store.rootStore.filterStore.courtCaseSearch : ""}
        searchType={"secondary"}
        placeholder="Etsi päätöksen nimellä"
        onSelect={handleValueSelcted}
        onClear={() => handleOnClear(true)}
        onChange={(value: string) => handleOnChange(value)}        
        showHistory={true}
        onKeyDown={handleEmptyField}
        style={{ width: 320, position: "absolute" }}
      />
    </div>
  );
});

export default SearchInput;
