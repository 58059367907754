import { Accordion, AccordionItem, Button } from "@appkit4/react-components";
import { useContext, useEffect, useState } from "react";
import { CourtCaseType, RelationsType, commentListItem } from "../../@types/general";
import { StoreContext } from "../../App";
import { IRootStore } from "../../store/RootStore";
import {
  addNodeSelectedAttributes,
  resetEdgeSelectedAttributes,
  resetNodeSelectednAttributes,
} from "../graph/Graph";
import CourtCaseSection from "./CourtCaseSection";
import "./MetadataCard.scss";
import RelationSection from "./RelationSection";
import AddCommentModal from "./AddCommentModal";

type MetadataCardProps = {
  courtCase: CourtCaseType | undefined;
  relation: RelationsType | undefined;
};


function MetadataCard({ courtCase, relation }: MetadataCardProps) {
  const [activateMetadataCard, isMetadataCardActive] = useState<boolean>(false);
  const [isCardExpanded, setIsCardExpanded] = useState<boolean>(false);
  const [activeAccordionItems, setActiveAccordionItems] = useState<string[]>(
    ["4"]
  );
  const [isAddCommentModalVisible, setAddCommentModalVisible] =
    useState<boolean>(false);

  const [edgeCommentList, setEdgeCommentList] = useState<commentListItem[]>();
  const [nodeCommentList, setNodeCommentList] = useState<commentListItem[]>();
  const courtCaseStore = useContext<IRootStore>(StoreContext).courtCaseStore;

  useEffect(() => {
    setEdgeCommentList(undefined)
    setNodeCommentList(undefined)
    isMetadataCardActive(false)
  }, [relation, courtCase]);

  function onClickAccordion(activeKeys: string[]) {
    setActiveAccordionItems(activeKeys);
  }

  async function addNodeComment(connectionCommentText: string): Promise<boolean> {
    const commentList = await courtCaseStore.addNewCommentToEdgeorNode(connectionCommentText)
    setNodeCommentList(commentList)
    return true
  }

  async function addEdgeComment(connectionCommentText: string): Promise<boolean> {
    const commentList = await courtCaseStore.addNewCommentToEdgeorNode(connectionCommentText)
    setEdgeCommentList(commentList)
    return true
  }

  function handleAddCommentClick() {
    setAddCommentModalVisible(true);
  }

  function toggleHideCard() {
    courtCaseStore.clearSelectedCourtCaseAndEdge();
    resetEdgeSelectedAttributes(relation?.group);
    resetNodeSelectednAttributes();
    setIsCardExpanded(false);
    isMetadataCardActive(!activateMetadataCard);
  }

  function renderSingleCourtCase() {
    if (courtCase) {
      return (
        <div>
          <CourtCaseSection courtCase={courtCase} />
          <Accordion
            multiple={true}
            onClick={onClickAccordion}
            activeKeys={activeAccordionItems}
          >

            <AccordionItem title="Kommentit" style={{fontSize:"16px"}}itemKey="3" >
              <RelationSection setCommentList={setNodeCommentList} commentList={nodeCommentList} />
              <Button
                className="add-comment-button"
                kind="secondary"
                icon="icon-circle-plus-outline"
                onClick={handleAddCommentClick}>
                Lisää kommentti
              </Button>

            </AccordionItem>
            <AddCommentModal
              isVisible={isAddCommentModalVisible}
              setIsVisible={setAddCommentModalVisible}
              setAddCommentToEdgeOrNode={addNodeComment}
            />

          </Accordion>
        </div>
      );
    }
  }

  function renderCourtCasesWithRelation() {
    if (relation) {
      const courtCaseObject = courtCaseStore.getRelationsNodes(relation);
      if (courtCaseObject) {

        addNodeSelectedAttributes(`a${courtCaseObject.startNode.id}`);
        addNodeSelectedAttributes(`a${courtCaseObject.sinkNode.id}`);
        return isCardExpanded
          ? renderCourtCasesSideBySide(
            courtCaseObject.startNode,
            courtCaseObject.sinkNode
          )
          : renderCourtCasesInColumn(
            courtCaseObject.startNode,
            courtCaseObject.sinkNode
          );
      }
    }
  }


  function renderCourtCasesSideBySide(
    leftCourtCase: CourtCaseType,
    rightCourtCase: CourtCaseType
  ): JSX.Element {

    return (
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{width:"50%"}}>
            <h2 style={{ paddingLeft: "20px" }}>
              {leftCourtCase.label !== "None"
                ? leftCourtCase.label
                : leftCourtCase.title}
            </h2>
            <CourtCaseSection courtCase={leftCourtCase} />
          </div>
          <div style={{width:"50%"}}>
            <h2 style={{ paddingLeft: "20px" }}>
              {rightCourtCase.label !== "None"
                ? rightCourtCase.label
                : rightCourtCase.title}
            </h2>
            <CourtCaseSection courtCase={rightCourtCase} />
          </div>
        </div>
        <div>
          <h2 style={{ padding: "0.3rem" }}>Yhteyden kommentit</h2>
          <RelationSection setCommentList={setEdgeCommentList} commentList={edgeCommentList} />
          <Button
            className="add-comment-button"
            kind="secondary"
            icon="icon-circle-plus-outline"
            onClick={handleAddCommentClick}>
            Lisää kommentti
          </Button>
          <AddCommentModal
            isVisible={isAddCommentModalVisible}
            setIsVisible={setAddCommentModalVisible}
            setAddCommentToEdgeOrNode={addEdgeComment}
          />
        </div>
      </>
    );
  }


  function renderCourtCasesInColumn(
    topCourtCase: CourtCaseType,
    bottomCourtCase: CourtCaseType
  ): JSX.Element {
    return (
      <Accordion
        multiple={true}
        onClick={onClickAccordion}
        activeKeys={activeAccordionItems}
      >
        <AccordionItem
          title={
            topCourtCase.label !== "None"
              ? topCourtCase.label
              : topCourtCase.title
          }
          itemKey="1"
        >
          <CourtCaseSection courtCase={topCourtCase} />
        </AccordionItem>
        <AccordionItem
          title={
            bottomCourtCase.label !== "None"
              ? bottomCourtCase.label
              : bottomCourtCase.title
          }
          itemKey="2"
        >
          <CourtCaseSection courtCase={bottomCourtCase} />
        </AccordionItem>
        <AccordionItem title="Yhteys" itemKey="3">
          <RelationSection setCommentList={setEdgeCommentList} commentList={edgeCommentList} />
          <Button
            className="add-comment-button"
            kind="secondary"
            icon="icon-circle-plus-outline"
            onClick={handleAddCommentClick}>
            Lisää kommentti
          </Button>


        </AccordionItem>
        <AddCommentModal
          isVisible={isAddCommentModalVisible}
          setIsVisible={setAddCommentModalVisible}
          setAddCommentToEdgeOrNode={addEdgeComment}
        />

      </Accordion>

    );
  }

  return (
    <div
      className={`floating-metadata-container ${activateMetadataCard ? "hidden" : ""} ${isCardExpanded ? "expanded" : ""
        }`}
    >
      <div className="metadata-header">
        <Button
          add
          kind="secondary"
          className="ap-font-16"
          icon={isCardExpanded ? "icon-minimize-fill" : "icon-expand-fill"}
          onClick={() => setIsCardExpanded(!isCardExpanded)}
        />
        <Button
          add
          kind="secondary"
          className="ap-font-16"
          icon="icon-close-fill"
          onClick={toggleHideCard}
        />
      </div>
      {renderSingleCourtCase()}
      {renderCourtCasesWithRelation()}
    </div>


  );
}

export default MetadataCard;
