import { Button, Modal, TextArea } from "@appkit4/react-components";
import { useState } from "react";
import { isEmptyOrSpaces } from "../../assets/helpers/helpers";

type AddConnectionModalProps = {
  isVisible: boolean;
  setIsVisible(showModal: boolean): void;
  setAddCommentToEdgeOrNode(addComment: string): Promise<boolean>;
};

function AddCommentModal({
  isVisible,
  setIsVisible,
  setAddCommentToEdgeOrNode: addCommentToEdgeOrNode
}: AddConnectionModalProps) {

  const [connectionCommentText, setConnectionCommentText] =
    useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>();
  const [isDisabled, setIsDisabled] = useState<boolean>();
  
  function handleOnClose() {
    setIsVisible(false);
    setConnectionCommentText("");
    setIsLoading(false)
    setIsDisabled(false)

  }

  async function handleAddCommenntClick() {
    setIsLoading(true)
    setIsDisabled(true)
    if (await addCommentToEdgeOrNode(connectionCommentText))
      handleOnClose()
  }

  function isAddConnectionButtonDisabled() {
    return isEmptyOrSpaces(connectionCommentText)
  }

  function renderModalContent() {
    return (
      <div className="connection-selection">
        <TextArea
          title={"Kirjoita kommentti"}
          maxLength={420}
          style={{ width: "100%" }}
          value={connectionCommentText}
          onChange={setConnectionCommentText}
        ></TextArea>
      </div>
    );
  }

  const footer = (
    <div className="add-connection-footer">
      <Button kind="negative" onClick={handleOnClose} disabled={isDisabled}>
        Peruuta
      </Button>
      <Button onClick={handleAddCommenntClick} disabled={isAddConnectionButtonDisabled()} loading={isLoading} >Lisää kommentti</Button>
    </div>
  );

  const header = (
    <h3>
      Lisää kommentti yhteydelle
    </h3>
  );

  return (
    <Modal
      className="add-connection-modal"
      visible={isVisible}
      ariaLabel={"Lisää kommentti"}
      onCancel={() => handleOnClose()}
      modalStyle={{ width: "33.75rem" }}
      header={header}
      footer={footer}
    >
      {renderModalContent()}
    </Modal>
  );
}


export default AddCommentModal;