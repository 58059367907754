import { Switch } from "@appkit4/react-components";
import { useEffect, useRef, useState } from "react";

type SearchByDateProps = {
  name: string;
  applyFilter: boolean;
  handleApplyFilter(field: string, value: boolean): void;
  handleValueChanged(field: string, value: any): void;
  selectedValues: string[] | undefined;
  minDate: string | undefined;
  maxDate: string | undefined;
};

function SearchByDate({
  name,
  applyFilter,
  handleApplyFilter,
  handleValueChanged,
  selectedValues,
  minDate,
  maxDate,
}: SearchByDateProps) {
  const dateInputRef = useRef(null);
  const [dateValues, setDateValues] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });

  useEffect(() => {
    setDateValues({
      start: selectedValues ? selectedValues[0] : "",
      end: selectedValues ? selectedValues[1] : "",
    });
  }, [selectedValues]);

  const handleChange = (e: any) => {
    if (!e.target.value || !e.target.name) return;

    handleValueChanged(e.target.name, e.target.value);
    const newState = { ...dateValues };
    if (e.target.name === "startDate") {
      newState.start = e.target.value;
    }
    if (e.target.name === "endDate") {
      newState.end = e.target.value;
    }
    setDateValues(newState);
  };

  const renderSwitch = () => {
    if (selectedValues && selectedValues.length > 0) {
      return (
        <Switch
          checked={applyFilter && selectedValues.length > 0}
          onChange={(e) => handleApplyFilter(name, e)}
        />
      );
    }

    return (
      <Switch
        disabled
        checked={applyFilter && selectedValues && selectedValues.length > 0}
        onChange={(e) => handleApplyFilter(name, e)}
      />
    );
  };

  return (
    <div className="filter-section">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h4>Antopäivä</h4>
        <div className="filter-date">
          <label className="filter-date-title">Antopäivä</label>
          <input
            name="startDate"
            className="date-text"
            value={dateValues.start}
            type="date"
            max={maxDate ? maxDate.toString() : undefined}
            onChange={handleChange}
            ref={dateInputRef}
          />
          <span className="date-text"> - </span>
          <input
            name="endDate"
            className="date-text"
            value={dateValues.end}
            min={minDate}
            type="date"
            onChange={handleChange}
            ref={dateInputRef}
          />
        </div>
      </div>
      {renderSwitch()}
    </div>
  );
}

export default SearchByDate;
