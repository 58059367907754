import { FeedsCommentItem, FeedsComments } from "@appkit4/react-components";
import { useContext, useEffect } from "react";
import { StoreContext } from "../../App";
import { commentListItem } from "../../@types/general";
import './MetadataCard.scss'
import { Loading } from "@appkit4/react-components/loading";
import { IRootStore } from "../../store/RootStore";

type RelationSectionProps = {
  setCommentList(setCommentList: commentListItem[]): void;
  commentList: commentListItem[] | undefined;
};

 function RelationSection({ setCommentList, commentList }: RelationSectionProps) {
  const courtCaseStore = useContext<IRootStore>(StoreContext).courtCaseStore;

  useEffect(() => {
    async function fetchComments() {
      if (commentList === undefined){
        const commentsLists = await courtCaseStore.getCommentsForNodesAndEdges()
        setCommentList(commentsLists)
      }
    }

    fetchComments().catch(console.error)

  }, [commentList, courtCaseStore, setCommentList])

  if(commentList === undefined){
     return renderLoader()
     
  }
  
  if (courtCaseStore.selectedCommentList.length <= 0) {
    return (<>
      <span>Ei kommentteja</span>
    </>)
  }

     function renderLoader() {
          return (
            <div className="loader-wrapper">
              <div className="loader">
                <Loading
                  loadingType="circular"
                  circularWidth="36px"
                  indeterminate={true}
                  compact={false}
                ></Loading>
               </div>
             </div>
          );
    };

  return (

    <>
      <FeedsComments
        className="comments-accordian"
        type={"comments"}
        renderCommentList={() => {
          return courtCaseStore.selectedCommentList?.map((item, index) => (
            <FeedsCommentItem
              item={item}
              key={index.toString()}
            ></FeedsCommentItem>
          ));

        }}
      ></FeedsComments>
    </>
  );

}

export default RelationSection;
