import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { StoreContext } from "../App";
import { IRootStore } from "../store/RootStore";

export default function ProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let navigate = useNavigate();
  const store = useContext<IRootStore>(StoreContext);

  useEffect(() => {
    async function getUserInfo() {
      try {
        restoreQueryParameters()
        const userInfo = await store.getUserInfo();
       
        if (userInfo) {
          setIsLoading(false);
        } else {
          initiateLogin()
        }
      }catch {
        initiateLogin()
      }
    }
    function initiateLogin(){   
    saveQuaryParameters()
      window.location.assign(
        `${process.env.REACT_APP_Backend}/authorization/login`
      );
    }

    function saveQuaryParameters(){
      const urlParams = new URLSearchParams(window.location.search).toString();
      localStorage.setItem("queryParams", urlParams);
    }

    function restoreQueryParameters(){
      const savedQueryParams = localStorage.getItem("queryParams");
      if(savedQueryParams){
        navigate(`${window.location.pathname}?${savedQueryParams}`, {replace: true})
        localStorage.removeItem("queryParams")
      }
    }

    if (!window.location.href.includes("logout")) {
        getUserInfo()    
    }
  }, [store, navigate]);

  return <>{isLoading ? <Spinner /> : <>{children}</>}</>;
}
