import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { EdgeType } from "../@types/enums";
import {
  AppliedFilterValues,
  CommentType,
  CourtCaseAndRelationsType,
  CourtCaseDBType,
  CourtCaseType,
  GraphData,
  NewCommentType,
  NewRelationType,
  NodesRelationsProperties,
  RelationsDBType,
  RelationsType,
  UserType,
  commentListItem,
} from "../@types/general";
import { areArraysEqual, getInstanceEnum } from "../assets/helpers/helpers";
import { getData, postData } from "../services/ApiActions";
import { IRootStore, StoreState } from "./RootStore";
import { convertCommentToCommentListItem } from "../assets/helpers/helpers";
import FilterStore from "./FilterStore";

export interface ICourtCaseStore {
  rootStore: IRootStore;
  baseUrl: string;
  allCourtCases: CourtCaseType[];
  allRelations: RelationsType[];
  activeCourtCases: CourtCaseType[];
  activeRelations: RelationsType[];
  selectedCourtCase: CourtCaseType | undefined;
  selectedRelation: RelationsType | undefined;
  shouldGraphRender: string | undefined;
  getAllCourtCasesAndRelations(
    isFirstRender?: boolean,
    updateActiveCases?: boolean
  ): void;
  getFilterCourtCasesAndRelations(
    isFirstRender?: boolean,
    inMemoryFilter?: boolean
  ): void;
  getOneCourtCaseAndRelations(title: string): void;
  getCourtCases(): Promise<CourtCaseType[]>;
  getRelations(): Promise<RelationsType[]>;
  getGraphData(): GraphData;
  getRelationsNodes(
    relation: RelationsType
  ): { startNode: CourtCaseType; sinkNode: CourtCaseType } | undefined;
  getSelectedRelationComments(): Promise<commentListItem[]>;
  getSelectedNodeComments(): Promise<commentListItem[]>;
  getCommentsForNodesAndEdges(): Promise<commentListItem[]>;
  getComments(commentID: string | undefined): Promise<CommentType[]>;
  setSelectedCourtCase(courtCase: CourtCaseType): void;
  setSelectedEdge(edgeID: string): void;
  clearSelectedCourtCaseAndEdge(): void;
  renderGraph(): void;
  addNewCourtCaseRelation(
    courtCaseOneId: string,
    courtCaseTwoId: string,
    relationDescriptionText: string
  ): void;
  addNewCommentToEdgeorNode(
    selectedEdgeComments: string
  ): Promise<commentListItem[]>;
  selectedCommentList: commentListItem[];
}
class CourtCaseStore implements ICourtCaseStore {
  rootStore: IRootStore;
  baseUrl: string = "graph";
  allCourtCases: CourtCaseType[];
  allRelations: RelationsType[];
  activeCourtCases: CourtCaseType[];
  activeRelations: RelationsType[];
  selectedCourtCase: CourtCaseType | undefined;
  selectedRelation: RelationsType | undefined;
  shouldGraphRender: string | undefined;
  selectedCommentList: commentListItem[];

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    this.allCourtCases = [];
    this.allRelations = [];
    this.activeCourtCases = [];
    this.activeRelations = [];
    this.allCourtCases = [];
    this.selectedCourtCase = undefined;
    this.selectedCommentList = [];
  }

  clearSelectedCourtCaseAndEdge(): void {
    runInAction(() => {
      this.selectedCourtCase = undefined;
      this.selectedRelation = undefined;
    });
  }

  getRelationsNodes(
    relation: RelationsType
  ): { startNode: CourtCaseType; sinkNode: CourtCaseType } | undefined {
    const startNode = this.activeCourtCases.find(
      (v) => v.id === relation.source
    );
    const sinkNode = this.activeCourtCases.find(
      (v) => v.id === relation.target
    );
    if (startNode && sinkNode) {
      return { startNode: startNode, sinkNode: sinkNode };
    }
  }

  async getSelectedRelationComments(): Promise<commentListItem[]> {
    const commentsFromDB = await this.getComments(
      this.selectedRelation?.commentID
    );
    return (this.selectedCommentList =
      convertCommentToCommentListItem(commentsFromDB));
  }

  async getSelectedNodeComments(): Promise<commentListItem[]> {
    const commentsFromDB = await this.getComments(
      this.selectedCourtCase?.commentID
    );
    return (this.selectedCommentList =
      convertCommentToCommentListItem(commentsFromDB));
  }

  async getCommentsForNodesAndEdges(): Promise<commentListItem[]> {
    if (this.selectedCourtCase) {
      return await this.getSelectedNodeComments();
    } else {
      return await this.getSelectedRelationComments();
    }
  }

  async getComments(commentID: string | undefined): Promise<CommentType[]> {
    if (commentID === undefined) {
      return [];
    }
    let comments: CommentType[] = [];
    const response = await getData<CommentType[]>(
      `${this.baseUrl}/get_comment/${commentID}`
    );

    runInAction(() => {
      if (response) {
        comments = response;
      }
    });

    return comments;
  }

  async addNewCommentToEdgeorNode(
    commentText: string
  ): Promise<commentListItem[]> {
    try {
      const user = await this.rootStore.getUserInfo();
      if (!user) throw Error("User is not defined");
      let source = undefined;
      let target = undefined;
      let commentID = undefined;
      if (this.selectedRelation?.source === undefined) {
        source = this.selectedCourtCase?.id;
        target = "";
        commentID = this.selectedCourtCase?.commentID;
      } else {
        source = this.selectedRelation?.source;
        target = this.selectedRelation?.target;
        commentID = this.selectedRelation.commentID;
      }
      const newComment: NewCommentType = {
        comment: commentText,
        commentID: commentID,
        firstName: user.given_name,
        lastName: user.family_name,
        email: user.email,
        source: source,
        target: target,
      };
      const response = await postData<NewCommentType, CommentType[]>(
        `${this.baseUrl}/add_comment`,
        newComment
      );
      runInAction(() => {
        if (response) {
          this.selectedCommentList = convertCommentToCommentListItem(response);
          response.forEach((response) => {
            if (this.selectedRelation) {
              this.selectedRelation.commentID = response.commentID;
            } else if (this.selectedCourtCase) {
              this.selectedCourtCase.commentID = response.commentID;
            }
          });
        }
      });
    } catch (exception) {
      runInAction(() => {
        this.rootStore.storeState = StoreState.ERROR;
      });
    }
    return this.selectedCommentList;
  }

  async addNewCourtCaseRelation(
    courtCaseOneId: string,
    courtCaseTwoId: string,
    relationDescriptionText: string
  ) {
    try {
      runInAction(() => {
        this.rootStore.storeState = StoreState.LOADING;
      });

      const user = await this.rootStore.getUserInfo();
      if (!user) throw Error("User is not defined");

      const newRelation: NewRelationType = this.createNewRelationObject(
        courtCaseOneId,
        courtCaseTwoId,
        relationDescriptionText,
        user
      );

      const response = await postData<NewRelationType, RelationsDBType[]>(
        `${this.baseUrl}/add_relation`,
        newRelation
      );

      runInAction(async () => {
        if (response) {
          const addedRelations =
            this.convertRelationsDBTypeToRelationsType(response);

          if (!addedRelations) {
            throw Error("Error adding relation to the graph");
          }
          this.allRelations = [...this.allRelations, ...addedRelations];
          this.activeRelations = [...this.activeRelations, ...addedRelations];
          const isCourtCaseOneInActiveCases = this.activeCourtCases.find(
            (v) => v.id === courtCaseTwoId
          );
          if (!isCourtCaseOneInActiveCases) {
            const courtCase = this.allCourtCases.find(
              (v) => v.id === courtCaseTwoId
            );
            if (courtCase) {
              this.activeCourtCases = [...this.activeCourtCases, courtCase];
            } else {
              await this.getAllCourtCasesAndRelations(false, false);
              const courtCase = this.allCourtCases.find(
                (v) => v.id === courtCaseTwoId
              );
              if (courtCase) {
                this.activeCourtCases = [...this.activeCourtCases, courtCase];
              }
            }
          }

          this.renderGraph();
        }
        this.rootStore.storeState = StoreState.READY;
      });
    } catch (exception) {
      runInAction(() => {
        this.rootStore.storeState = StoreState.ERROR;
      });
    }
  }

  createNewRelationObject(
    courtCaseOneId: string,
    courtCaseTwoId: string,
    relationDescriptionText: string,
    user: UserType
  ): NewRelationType {
    const newRelation: NewRelationType = {
      courtCaseOneId: courtCaseOneId,
      courtCaseTwoId: courtCaseTwoId,
      relationDescription: relationDescriptionText,
      creatingUserFirstName: user.given_name,
      creationUserLastName: user.family_name,
      creatingUserEmail: user.email,
    };

    return newRelation;
  }

  async getAllCourtCasesAndRelations(
    isFirstRender = false,
    updateActiveCases = true
  ) {
    this.rootStore.storeState = StoreState.LOADING;
    this.rootStore.filterStore.setCourtCaseSearchValue(undefined);
    if (isFirstRender) {
      this.rootStore.filterStore.readFilterParams();
    }
    if (
      Object.values(this.rootStore.filterStore.appliedFilters).includes(true)
    ) {
      await this.getFilterCourtCasesAndRelations(isFirstRender);
      return;
    }

    const { isError, response } = this.rootStore.responseHandler(
      await getData<NodesRelationsProperties>(
        `${this.baseUrl}/get_properties_documents_and_relations`
      )
    );

    if (!isError && response) {
      runInAction(() => {
        const courtCases = this.convertCourtCaseDBTypeTOCourtCaseType(
          response.court_cases
        );
        const relations = this.convertRelationsDBTypeToRelationsType(
          response.relations
        );
        this.allCourtCases = courtCases;
        this.allRelations = relations;
        if (updateActiveCases) {
          this.activeCourtCases = courtCases;
          this.activeRelations = relations;
        }
        this.rootStore.filterStore.setAllProperties(response.properties);

        this.renderGraph();
        this.rootStore.storeState = StoreState.READY;
      });
    }
  }

  async getOneCourtCaseAndRelations(title: string) {
    this.rootStore.storeState = StoreState.LOADING;

    const formData = new FormData();
    formData.append("title", title);

    const { isError, response } = this.rootStore.responseHandler(
      await postData<any, CourtCaseAndRelationsType>(
        `${this.baseUrl}/filter_by_title`,
        formData
      )
    );

    this.rootStore.filterStore.setCourtCaseSearchValue(title);
    runInAction(() => {
      if (!isError && response) {
        this.activeCourtCases = this.convertCourtCaseDBTypeTOCourtCaseType(
          response.nodes
        );

        const outgoingEdges = this.convertRelationsDBTypeToRelationsType(
          response.outgoing
        );
        const incomingEdges = this.convertRelationsDBTypeToRelationsType(
          response.incoming
        );
        this.activeRelations = outgoingEdges.concat(incomingEdges);

        this.renderGraph();
      }
      this.rootStore.storeState = StoreState.READY;
    });
  }

  async getFilterCourtCasesAndRelations(isFirstRender?: boolean) {
    this.rootStore.storeState = StoreState.LOADING;

    type ObjectKey = keyof typeof this.rootStore.filterStore.appliedFilters;
    const keys = Object.keys(this.rootStore.filterStore.appliedFilters);

    const dynamicValues: { key: ObjectKey; value: any; operator: string }[] =
      [];

    keys.forEach((v) => {
      const key = v as ObjectKey;

      if (this.rootStore.filterStore.appliedFilters[key]) {
        const values = this.rootStore.filterStore.appliedFilterValues[key];
        if (values && values.length > 0) {
          let operator = "OR"; // default operator
          if (key === "section") {
            operator = this.rootStore.filterStore.filterLogicSection;
          } else if (key === "law") {
            operator = this.rootStore.filterStore.filterLogicLaw;
          }
          if (key === "decision_date") {
            const dateNow = new Date();
            const startDateSeconds = values[0] ?? "1900-1-1";
            const endDateSeconds =
              values[1] ??
              `${dateNow.getFullYear()}-${
                dateNow.getMonth() + 1
              }-${dateNow.getDate()}`;
            dynamicValues.push({
              key,
              value: [`${startDateSeconds}`, `${endDateSeconds}`],
              operator,
            });
          } else {
            dynamicValues.push({ key, value: values, operator });
          }
        }
      }
    });

    if (!this.rootStore.filterStore.courtCaseSearch) {
      if (Object.keys(dynamicValues).length === 0) {
        if (
          !areArraysEqual(
            this.activeCourtCases.map((v) => v.id),
            this.allCourtCases.map((v) => v.id)
          )
        ) {
          this.activeCourtCases = [...this.allCourtCases];
          this.activeRelations = [...this.allRelations];

          this.renderGraph();
        }
        this.rootStore.storeState = StoreState.READY;
        return;
      }

      let jsonDynamicValues = { filters: dynamicValues };

      const { isError, response } = this.rootStore.responseHandler(
        await postData<any, any>(`${this.baseUrl}/filter/?`, jsonDynamicValues)
      );
      if (isFirstRender) {
        const { isError, response: properties } =
          this.rootStore.responseHandler(
            await getData<any>(`${this.baseUrl}/properties`)
          );
        if (!isError && properties) {
          this.rootStore.filterStore.setAllProperties(properties);
        }
      }

      runInAction(() => {
        if (!isError && response) {
          this.activeCourtCases = this.convertCourtCaseDBTypeTOCourtCaseType(
            response.nodes
          );
          this.activeRelations = this.convertRelationsDBTypeToRelationsType(
            response.edges
          );
        }
      });
    }

    if (
      !areArraysEqual(
        this.activeCourtCases.map((v) => v.id),
        this.allCourtCases.map((v) => v.id)
      ) ||
      !areArraysEqual(
        this.activeRelations.map((v) => v.source + v.target),
        this.allRelations.map((v) => v.source + v.target)
      )
    ) {
      this.renderGraph();
    }

    runInAction(() => {
      this.rootStore.storeState = StoreState.READY;
    });
  }

  setSelectedCourtCase(courtCase: CourtCaseType) {
    runInAction(() => {
      this.selectedCourtCase = courtCase;
      this.selectedRelation = undefined;
    });
  }

  setSelectedEdge(edgeID: string): void {
    runInAction(() => {
      const relation = this.activeRelations.find(
        (currentRelation) => currentRelation.id === edgeID
      );

      if (relation) {
        this.selectedRelation = relation;
        this.selectedCourtCase = undefined;
      }
    });
  }

  async getCourtCases() {
    const { isError, response } = this.rootStore.responseHandler(
      await getData<CourtCaseDBType[]>(`${this.baseUrl}/all_documents`)
    );
    if (!isError && response) {
      return this.convertCourtCaseDBTypeTOCourtCaseType(response);
    }
    return [];
  }

  async getRelations() {
    const { isError, response } = this.rootStore.responseHandler(
      await getData<RelationsDBType[]>(`${this.baseUrl}/all_relations`)
    );

    if (!isError && response) {
      return this.convertRelationsDBTypeToRelationsType(response);
    }
    return [];
  }

  getGraphData() {
    if (this.rootStore.filterStore.courtCaseSearch) {
      const filteredNodes = this.activeCourtCases.filter((v) => {
        let keyWords: string[] = [];
        if (v.keyWords) {
          keyWords = v.keyWords[0].split("; ").map((k) => k.trim());
        }

        return this.rootStore.filterStore.appliedFilters.instance
          ? this.rootStore.filterStore.appliedFilterValues.instance?.includes(
              v.instance
            )
          : true && this.rootStore.filterStore.appliedFilters.keywords
          ? this.rootStore.filterStore.appliedFilterValues.keywords?.some((k) =>
              keyWords.includes(k)
            )
          : true && this.rootStore.filterStore.appliedFilters.law
          ? this.rootStore.filterStore.appliedFilterValues.law?.includes(v.law)
          : true && this.rootStore.filterStore.appliedFilters.fieldsOfLaw
          ? this.rootStore.filterStore.appliedFilterValues.fieldsOfLaw?.includes(
              v.type
            )
          : true;
      });

      const filteredNodeIds = filteredNodes.map((v) => v.id);
      const filteredEdges = this.activeRelations.filter(
        (v) =>
          filteredNodeIds.includes(v.source) &&
          filteredNodeIds.includes(v.target) &&
          this.rootStore.filterStore.edgesToDisplay.includes(v.group)
      );

      return {
        nodes: filteredNodes,
        links: removeDuplicateManualConnectionEdges(filteredEdges),
        displayBidirectionalEdges: this.displayBidirectionalEdges(),
      };
    }

    const edgesToDisplay = this.activeRelations.filter((v) =>
      this.rootStore.filterStore.edgesToDisplay.includes(v.group)
    );

    return {
      nodes: this.activeCourtCases,
      links: removeDuplicateManualConnectionEdges(edgesToDisplay),
      displayBidirectionalEdges: this.displayBidirectionalEdges(),
    };
  }

  displayBidirectionalEdges(): boolean {
    if (
      this.rootStore.filterStore.edgesToDisplay.includes(EdgeType.referencedIn)
    ) {
      return this.rootStore.filterStore.edgesToDisplay.includes(
        EdgeType.regexConnection
      );
    }
    return false;
  }

  renderGraph() {
    runInAction(() => {
      this.shouldGraphRender = uuidv4();
    });
  }

  convertCourtCaseDBTypeTOCourtCaseType(
    courtCaseDBType: CourtCaseDBType[]
  ): CourtCaseType[] {
    if (!courtCaseDBType) return [];
    return courtCaseDBType.map((v) => ({
      id: v.id,
      label:
        v.properties.ECLI[0].value === "None"
          ? v.properties.title[0].value
          : v.properties.ECLI[0].value,
      title: v.properties.title[0].value,
      group: getInstanceEnum(v.properties.instance[0].value),
      strength: 10,
      instance: v.properties.instance[0].value,
      law: v.properties.law[0].value,
      section: v.properties.section?.map((k) => k.value),
      date: new Date(v.properties.decision_date[0].value),
      keyWords: v.properties.keywords?.map((k) => k.value),
      type: "",
      mFilesId: v.properties.m_files_id[0].value,
      field_of_law:
        v.properties.field_of_law?.length > 0
          ? v.properties.field_of_law[0].value
          : undefined,
      commentID:
        v.properties.commentID?.length > 0
          ? v.properties.commentID[0].value
          : undefined,
      directNodes: v.directNodes,
    }));
  }

  convertRelationsDBTypeToRelationsType(
    relationsDBType: RelationsDBType[]
  ): RelationsType[] {
    if (!relationsDBType) return [];  

    const response = relationsDBType.filter((v) => v.inV !== v.outV).map((v) => ({
      id: v.id,
      source: v.outV,
      target: v.inV,
      value: 1,
      strength: 5,
      group: this.getEdgeType(v.label),
      commentID: v.properties?.commentID,
    }));
    return response;
  }

  getEdgeType(string: String): EdgeType {
    if (string === "regex_connection") return EdgeType.regexConnection;

    if (string === "referenced_in") return EdgeType.referencedIn;

    if (string === "user_made_reference") return EdgeType.manualConnection;

    return EdgeType.undefined;
  }
}

export default CourtCaseStore;

function removeDuplicateManualConnectionEdges(
  edges: RelationsType[]
): RelationsType[] {
  const manualConnectionEdgeSources = new Set(
    edges
      .filter((v) => v.group === EdgeType.manualConnection)
      .map((v) => v.source)
  );
  const individualManualConnections: RelationsType[] = [];
  const addedEdgeIds: string[] = [];
  manualConnectionEdgeSources.forEach((v) => {
    const edgeToAdd = edges.find(
      (edge) => edge.source === v || edge.target === v
    );

    if (edgeToAdd) {
      if (!addedEdgeIds.includes(edgeToAdd.source + edgeToAdd.target)) {
        individualManualConnections.push(edgeToAdd);
        addedEdgeIds.push(edgeToAdd.source + edgeToAdd.target);
        addedEdgeIds.push(edgeToAdd.target + edgeToAdd.source);
      }
    }
  });

  const regexConnections = edges.filter(
    (v) => v.group !== EdgeType.manualConnection
  );

  return [...regexConnections, ...individualManualConnections];
}
