import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import "@appkit4/react-components/dist/styles/appkit4-react.min.css";
import { MainContainer, LogoutContainer } from "./containers";
import RootStore, { IRootStore } from "./store/RootStore";
import SnackNotification from "./components/SnackNotification";
import { SnackbarProvider } from "notistack";
import ProtectedRoute from "./services/ProtectedRoute";
import ErrorPage from "./containers/ErrorPage";

const rootStore: IRootStore = new RootStore();

export const StoreContext = React.createContext(rootStore);

const snackbarOptions = {
  userNotification: SnackNotification,
};

function App() {
  return (
    <StoreContext.Provider value={rootStore}>
      <SnackbarProvider Components={snackbarOptions}>
        <Routes>
          <Route path="/logout" element={<LogoutContainer />} />
          <Route path="/error" element={<ErrorPage />}/>
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <MainContainer />
              </ProtectedRoute>
            }
          />
        </Routes>
      </SnackbarProvider>
    </StoreContext.Provider>
  );
}

export default App;
