import { getData } from "./ApiActions";

export const getParameters = (queryString: string) => {
  let params = {};
  let pairs = queryString.substring(1).split("&");

  pairs.forEach((m) => {
    let vals = m.split("=");
    Object.assign(params, { [vals[0]]: vals[1] });
  });
  return params;
};

export async function logoutUser() {
  await getData<any>(`${process.env.REACT_APP_Backend}/authorization/logout/`);
  window.location.assign("/logout");
}



