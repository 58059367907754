import React, { useContext, useState } from "react";
import { useD3 } from "../../hooks/useD3";
import * as d3 from "d3";
import { StoreContext } from "../../App";
import { observer } from "mobx-react-lite";
import { Loading } from "@appkit4/react-components/loading";
import { StoreState } from "../../store/RootStore";
import { EdgeType, Instance, NotificationType } from "../../@types/enums";
import "./Graph.scss";

const styles = {
  fullSize: {
    width: "100%",
    height: "100%",
  },
};

export function getNodeColorBasedOnGroup(nodeGroup) {
  const nodeColorOptions = new Map([
    [Instance.KorkeinHallintoOikeus, "#4577C9"],
    [Instance.Keskusverolautakunta, "#26776D"],
    [Instance.HallintoOikeus, "#D93954"],
    [Instance.EUTuomioistuin, "#FFB600"],
    [Instance.Verohallinto, "#3DD5B0"],
    [Instance.Oikaisulautakunta, "#9EE9D7"],
    [Instance.Muu, "#D2D7E2"],
    [Instance.undefined, "#000000"],
  ]);

  return nodeColorOptions.get(nodeGroup) ?? "#000000";
}

export function getLinkColorBasedOnLinktype(linkType) {
  const linkColorOptions = new Map([
    [EdgeType.referencedIn, "#D2D7E2"],
    [EdgeType.regexConnection, "#D2D7E2"],
    [EdgeType.manualConnection, "#FB7C4D"],
    [EdgeType.selectedEdge, "#E0301E"],
    [EdgeType.undefined, "#000000"],
  ]);

  return linkColorOptions.get(linkType) ?? "#000000";
}

const NetworkGraph = observer(() => {
  const [dimensions, setDimensions] = useState({ width: 800, height: 600 });
  const [prevTransform, setPrevTransform] = useState(undefined);
  const courtCaseStore = useContext(StoreContext).courtCaseStore;

  const ref = React.useRef();

  function getGraphData() {
    const graphData = courtCaseStore.getGraphData();
    const amountOfNodes = graphData.nodes?.length ?? 0  
    if (amountOfNodes > 1000) {
      graphData.nodes = graphData.nodes.slice(0, 999);
      const includedNodeIds = graphData.nodes.map(v => v.id)
      graphData.links = graphData.links.filter(v => includedNodeIds.includes(v.target) && includedNodeIds.includes(v.source))   
      const notification = {
        message: `Näytetään 1000/${amountOfNodes} oikeustapausta. Tarkenna hakua suodattimilla.`,
        type: NotificationType.WARNING,
        persist: false,
      };
      courtCaseStore.rootStore.showUserNotification(notification);
    }

    if (
      graphData.nodes.length <= 0 &&
      courtCaseStore.rootStore.storeState === StoreState.READY
    ) {
      if (areAnyFiltersApplied()) {
        const notification = {
          message: `Valitulla suodatinyhdistelmällä ei löytynyt yhtään oikeustapausta.`,
          type: NotificationType.WARNING,
          persist: false,
        };
        courtCaseStore.rootStore.showUserNotification(notification);
      }
    }
    handleNoDataAndNoFilters(graphData);
    return graphData;
  }

  function areAnyFiltersApplied() {
    const objectKeys = Object.keys(
      courtCaseStore.rootStore.filterStore.appliedFilters
    );
    if (
      objectKeys.find(
        (v) => courtCaseStore.rootStore.filterStore.appliedFilters[v]
      )
    ) {
      return true;
    }
    return false;
  }

  function handleNoDataAndNoFilters(graphData) {
    if (!areAnyFiltersApplied() && graphData.nodes.length === 0 && courtCaseStore.rootStore.storeState !== StoreState.LOADING) {
      ///do not run on first load
      courtCaseStore.getAllCourtCasesAndRelations();
    }
    
  }

  const d3ref = useD3(
    (svg) => {
      ForceGraph(svg, getGraphData(), {
        nodeId: (d) => d.id,
        linkSource: (d) => d.source,
        linkTarget: (d) => d.target,
        nodeGroup: (d) => d.group,
        nodeRadius: (d) => d.strength,
        linkStrokeWidth: (d) => d.strength,
        linkStroke: (d) => d.group,
        width: ref.current ? ref.current.offsetWidth : dimensions.width,
        height: ref.current ? ref.current.offsetHeight : dimensions.height,
        handleOnNodeClick: handleOnNodeClick,
        prevTransform: prevTransform,
      });
    },
    [courtCaseStore.shouldGraphRender, dimensions]
  );

  function ForceGraph(
    svg,
    {
      nodes, // an iterable of node objects (typically [{id}, …])
      links, // an iterable of link objects (typically [{source, target}, …])
      displayBidirectionalEdges, // this will draw bidirectional edges with arches
    },
    {
      nodeId = (d) => d.id, // given d in nodes, returns a unique identifier (string)
      linkId = (d) => d.id, // given d in links, returns a unique identifier (string)
      nodeGroup = (d) => d.group, // given d in nodes, returns an (ordinal) value for color
      nodeGroups, // an array of ordinal values representing the node groups
      nodeTitle = (d) => d.label, // given d in nodes, a title string
      nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
      nodeStroke = "none", // node stroke color
      nodeStrokeWidth = 1.5, // node stroke width, in pixels
      nodeStrokeOpacity = 1, // node stroke opacity
      nodeRadius = 5, // node radius, in pixels
      nodeStrength,
      linkSource = ({ source }) => source, // given d in links, returns a node identifier string
      linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
      linkStroke = (d) => d, // link stroke color
      linkStrokeOpacity = 0.7, // link stroke opacity
      linkStrokeWidth = 1.5, // given d in links, returns a stroke width in pixels
      linkStrokeLinecap = "round", // link stroke linecap
      linkStrength,
      colors = d3.schemeTableau10, // an array of color strings, for the node groups
      width = 900, // outer width, in pixels
      height = 600, // outer height, in pixels
      invalidation, // when this promise resolves, stop the simulation
      handleOnNodeClick,
      prevTransform,
    } = {}
  ) {
    d3.selectAll("svg > *").remove();

    // Compute values.
    const nodeMap = d3.map(nodes, nodeId).map(intern);
    const linkMap = d3.map(links, linkId).map(intern);
    const linkSourceMap = d3.map(links, linkSource).map(intern);
    const linkTargetMap = d3.map(links, linkTarget).map(intern);

    if (nodeTitle === undefined) nodeTitle = (_, i) => nodeMap[i];
    const nodeTitleMap = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
    const nodeGroupMap =
      nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
    const linkStrokeWidthMap =
      typeof linkStrokeWidth !== "function"
        ? null
        : d3.map(links, linkStrokeWidth);

    const linkStrokeMap = linkStroke == null ? null : d3.map(links, linkStroke);
    const nodeRadiusMap = nodeRadius == null ? null : d3.map(nodes, nodeRadius);

    // Replace the input nodes and links with mutable objects for the simulation.
    nodes = d3.map(nodes, (_, i) => ({ id: nodeMap[i], x: 0 }));
    links = d3.map(links, (_, i) => ({
      id: linkMap[i],
      source: linkSourceMap[i],
      target: linkTargetMap[i],
    }));

    // Compute default domains.
    if (nodeGroupMap && nodeGroups === undefined)
      nodeGroups = d3.sort(nodeGroupMap);

    // Construct the scales.
    const color =
      nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

    d3.selection.prototype.moveToFront = function () {
      return this.each(function () {
        this.parentNode.appendChild(this);
      });
    };

    d3.selection.prototype.moveToBack = function () {
      return this.each(function () {
        var firstChild = this.parentNode.firstChild;
        if (firstChild) {
          this.parentNode.insertBefore(this, firstChild);
        }
      });
    };

    // Construct the forces.
    const forceNode = d3.forceManyBody().strength(-70);
    const forceLink = d3
      .forceLink(links)
      .id(({ index: i }) => nodeMap[i])
      .distance(60);
    if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
    if (linkStrength !== undefined) forceLink.strength(linkStrength);

    const simulation = d3
      .forceSimulation(nodes)
      .force("link", forceLink)
      .force("charge", forceNode)
      .force("x", d3.forceX())
      .force("y", d3.forceY())
      .on("tick", ticked)
 
      .alpha(0.5);


    // add target node radius and link type to links
    links = links.map((d) => {
      const link = { ...d };

      link.target.radius = nodeRadiusMap[d.target.index];
      return link;
    });

    const minWidth = -width / 1.3;
    const minHeight = -height / 1.3;
    const maxWidth = width * 1.5;
    const maxHeight = height * 1.5;

    const main = svg
      .attr("viewBox", [minWidth, minHeight, maxWidth, maxHeight])
      .attr("pointer-events", "all")
      .attr("style", "max-width: 100%; height: auto; font-size: 5");

    function createArrows(svg, arrowTypes) {
      return svg
        .append("svg:defs")
        .selectAll("marker")
        .data(Object.keys(arrowTypes).map((value) => Number(value))) // Different link/path types can be defined here
        .enter()
        .append("svg:marker") // This section adds in the arrows
        .attr("id", (d) => `arrow-${EdgeType[d]}`)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 9)
        .attr("refY", 0)
        .attr("markerWidth", 4)
        .attr("markerHeight", 4)
        .attr("orient", "auto")
        .attr("fill", (d) => {
          return getLinkColorBasedOnLinktype(d);
        })
        .attr("stroke", (d) => getLinkColorBasedOnLinktype(d))
        .append("svg:path")
        .attr("d", "M0,-5L10,0L0,5");
    }

    function updateEdges(svg, links) {
      const link = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke-opacity", linkStrokeOpacity)
        .attr("stroke-linecap", linkStrokeLinecap)
        .attr("cursor", "pointer")
        .selectAll(".link")
        .data(links)
        .enter()
        .append("path")
        .attr("class", "link")
        .join("line")
        .attr("data-id", ({ index: i }) => linkMap[i])
        .attr("data-type", ({ index: i }) => linkStrokeMap[i])
        .attr("marker-end", ({ index: i }) => {
          if (linkStrokeMap[i] === EdgeType.regexConnection) {
            return `url(#arrow-${EdgeType[linkStrokeMap[i]]})`;
          }
        })
        .attr("data-index", ({ index: i }) => i)
        .on("mousedown", (event) => {
          try {
            const previousSelectedEdge =
              d3.select(".selected-edge")?._groups[0][0];
            if (previousSelectedEdge) {
              resetEdgeSelectedAttributes(
                linkStrokeMap[previousSelectedEdge.dataset.index]
              );
            }
          } catch {
            // empty on purpose
          }
          resetNodeSelectednAttributes();
          addEdgeSelectedAttributes(event.target);
          handleOnEdgeClick(event.target.dataset.id);
        });

      if (linkStrokeWidthMap) {
        link.attr("stroke-width", ({ index: i }) =>
          Math.sqrt(linkStrokeWidthMap[i])
        );
      }
      if (linkStrokeMap) {
        link.attr("stroke", ({ index: i }) =>
          getLinkColorBasedOnLinktype(linkStrokeMap[i])
        );
      }
      return link;
    }

    function createNodes(svg, nodes) {
      const node = svg
        .append("g")
        .attr("id", "nodes")
        .attr("fill", nodeFill)
        .attr("stroke", nodeStroke)
        .attr("stroke-opacity", nodeStrokeOpacity)
        .attr("stroke-width", nodeStrokeWidth)
        .attr("pointer-events", "fill")
        .attr("cursor", "grab")
        .selectAll("circle")
        .data(nodes)
        .join("circle")
        .attr("r", typeof nodeRadius !== "function" ? nodeRadius : null)
        .attr("data-id", ({ index: i }) => nodeMap[i])
        .attr("id", ({ index: i }) => `a${nodeMap[i]}`) //"a" is added because queryselectors does not work with id's that start with an integer
        .on("mousedown", (event) => {
          try {
            const previousSelectedEdge =
              d3.select(".selected-edge")?._groups[0][0];
            if (previousSelectedEdge) {
              resetEdgeSelectedAttributes(
                linkStrokeMap[previousSelectedEdge.dataset.index]
              );
            }
          } catch {
            // empty on purpose
          }
          resetNodeSelectednAttributes();
          addNodeSelectedAttributes(event.target.id);
          handleOnNodeClick(event.target.dataset.id);
        })
        .call(drag(simulation));

      if (nodeRadiusMap) node.attr("r", ({ index: i }) => nodeRadiusMap[i]);
      if (nodeGroupMap)
        node.attr("fill", ({ index: i }) =>
          getNodeColorBasedOnGroup(nodeGroupMap[i])
        );
      if (nodeTitleMap)
        node.append("title").text(({ index: i }) => nodeTitleMap[i]);

      return node;
    }

    function createTextLabels(svg, nodes) {
      return svg
        .append("g")
        .attr("class", "labels")
        .attr("cursor", "grab")
        .attr("pointer-events", "fill")
        .selectAll("text")
        .data(nodes)
        .enter()
        .append("text")
        .attr("x", 0)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .attr("data-id", ({ index: i }) => nodeMap[i])
        .text(({ index: i }) => nodeTitleMap[i])
        .on("mousedown", (event) => {
          try {
            const previousSelectedEdge =
              d3.select(".selected-edge")?._groups[0][0];
            if (previousSelectedEdge) {
              resetEdgeSelectedAttributes(
                linkStrokeMap[previousSelectedEdge.dataset.index]
              );
            }
          } catch {
            // empty on purpose
          }
          resetNodeSelectednAttributes();
          addNodeSelectedAttributes(`a${event.target.dataset.id}`);
          handleOnNodeClick(event.target.dataset.id);
        })
        .call(drag(simulation));
    }

    const arrow = createArrows(svg, EdgeType);

    const link = updateEdges(svg, links);

    const node = createNodes(svg, nodes);

    const text = createTextLabels(svg, nodes);

    // Handle invalidation.
    if (invalidation != null) invalidation.then(() => simulation.stop());

    function intern(value) {
      return value !== null && typeof value === "object"
        ? value.valueOf()
        : value;
    }

    function ticked() {
      for (let i = 0; i < 5; i++) {
        simulation.tick();
      }
      link.attr("d", linkArc);
      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      text.attr("x", (d) => d.x).attr("y", (d) => d.y);
    }

    function drag(simulation) {
      let startPosX;
      let startPosY;
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        startPosX = d.x;
        startPosY = d.y;
        d3.select(this).raise();
        node.attr("cursor", "grabbing");
        text.attr("cursor", "grabbing");
      }

      function dragged(event, d) {
        let currentScale;
        let currentScaleString;
        if (this.getAttribute("transform") === null) {
          currentScale = 1;
        }
        //case where we have transformed the circle
        else {
          currentScaleString = this.getAttribute("transform").split(" ")[1];
          currentScale = +currentScaleString.substring(
            6,
            currentScaleString.length - 1
          );
        }
        d3.select(this)
          .attr("cx", (d.x = startPosX + (event.x - startPosX) / currentScale))
          .attr("cy", (d.y = startPosY + (event.y - startPosY) / currentScale));
      }

      function dragended(event) {
        if (!event.active) simulation.alphaTarget(0);
        node.attr("cursor", "grab");
        text.attr("cursor", "grab");
      }

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    }

    let initialScale = 0.5;

    if (nodes.length < 20) {
      initialScale = 4;
    } else if (nodes.length < 100) {
      initialScale = 3;
    } else if (nodes.length < 200) {
      initialScale = 2;
    } else {
      const baseRequireedheight = 600;
      const baseNodes = 300;
      const diff = nodes.length - baseNodes;
      const requiredheight = baseRequireedheight + diff;
      const relation = height / requiredheight;
      initialScale = relation;
    }

    const transform = d3.zoomIdentity.scale(initialScale);

    zoomed({ transform: transform });

    const zoom = d3
    .zoom()
    .extent([
      [-width / 2, -height / 2],
          [width, height],
        ])
        .scaleExtent([initialScale, 8])
        .translateExtent([
          [minWidth, minHeight],
          [maxWidth, maxHeight],
        ])
        .on("zoom", zoomed)
    svg.call(zoom);
    
        svg.call(zoom.transform, d3.zoomIdentity.scale(initialScale).translate(0,0)) 


    function zoomed({ transform }) {
      setPrevTransform(transform);

      node.attr("transform", transform);
      link.attr("transform", transform);
      text.attr("transform", transform);
    }

    function linkArc(d) {
      const x1 = d.source.x,
        y1 = d.source.y,
        x2 = calculateX(
          d.target.x,
          d.target.y,
          d.source.x,
          d.source.y,
          d.target.radius
        ),
        y2 = calculateY(
          d.target.x,
          d.target.y,
          d.source.x,
          d.source.y,
          d.target.radius
        ),
        dx = x2 - x1,
        dy = y2 - y1,
        dr = Math.sqrt(dx * dx + dy * dy),
        // Defaults for normal edge.
        drx = displayBidirectionalEdges ? dr : 0,
        dry = displayBidirectionalEdges ? dr : 0,
        xRotation = 0, // degrees
        largeArc = 0, // 1 or 0
        sweep = 1; // 1 or 0

      return (
        "M" +
        x1 +
        "," +
        y1 +
        "A" +
        drx +
        "," +
        dry +
        " " +
        xRotation +
        "," +
        largeArc +
        "," +
        sweep +
        " " +
        x2 +
        "," +
        y2
      );
    }

    function calculateX(tx, ty, sx, sy, radius) {
      if (tx === sx) return tx; //if the target x == source x, no need to change the target x.
      var xLength = Math.abs(tx - sx); //calculate the difference of x
      var yLength = Math.abs(ty - sy); //calculate the difference of y
      //calculate the ratio using the trigonometric function
      var ratio = radius / Math.sqrt(xLength * xLength + yLength * yLength);
      if (tx > sx) return tx - xLength * ratio; //if target x > source x return target x - radius
      if (tx < sx) return tx + xLength * ratio; //if target x < source x return target x + radius
    }
    function calculateY(tx, ty, sx, sy, radius) {
      if (ty === sy) return ty; //if the target y == source y, no need to change the target y.
      var xLength = Math.abs(tx - sx); //calculate the difference of x
      var yLength = Math.abs(ty - sy); //calculate the difference of y
      //calculate the ratio using the trigonometric function
      var ratio = radius / Math.sqrt(xLength * xLength + yLength * yLength);
      if (ty > sy) return ty - yLength * ratio; //if target y > source y return target x - radius
      if (ty < sy) return ty + yLength * ratio; //if target y > source y return target x - radius
    }

    return svg.node();
  }

  const renderLoader = () => {
    if (courtCaseStore.rootStore.storeState === StoreState.LOADING) {
      return (
        <div id="loader-wrapper">
          <div id="loader">
            <Loading
              loadingType="circular"
              circularWidth="36px"
              indeterminate={true}
              compact={false}
            ></Loading>
          </div>
        </div>
      );
    }
  };

  function handleOnNodeClick(id) {
    if (id) {
      const clickedCourtCase = courtCaseStore.activeCourtCases.find(
        (n) => n.id === id
      );
      if (clickedCourtCase) {
        courtCaseStore.setSelectedCourtCase(clickedCourtCase);
      }
    }
  }

  function handleOnEdgeClick(id) {
    if (id) {
      courtCaseStore.setSelectedEdge(id);
    }
  }

  // needs optimization
  const handleResize = () => {
    setDimensions({
      width: ref.current ? ref.current.offsetWidth : 800,
      height: ref.current ? ref.current.offsetHeight : 600,
    });
  };

  window.addEventListener("resize", () => {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(() => {
      handleResize();
    }, 350);
  });

  return (
    <div className="courtCaseGraph-wrapper">
      <div ref={ref} style={styles.fullSize} className="courtCaseGraph">
        <svg ref={d3ref} id="graph"></svg>
        {renderLoader()}
      </div>
    </div>
  );
});

export function resetNodeSelectednAttributes() {
  d3.selectAll(".selected-node").attr("class", null).attr("stroke", "none");
}

export function addNodeSelectedAttributes(targetNode) {
  d3.select(`#${targetNode}`)
    .attr("class", "selected-node")
    .attr("stroke", "#FF0000");
}

export function resetEdgeSelectedAttributes(edgeType) {
  d3.selectAll(".selected-edge")
    .attr("class", null)
    .attr("stroke", getLinkColorBasedOnLinktype(edgeType))
    .attr("marker-end", () => {
      if (edgeType === EdgeType.regexConnection) {
        return `url(#arrow-${EdgeType[edgeType]})`;
      }
    });
}

export function addEdgeSelectedAttributes(targetEdge) {
  d3.select(targetEdge)
    .attr("class", "selected-edge")
    .attr("stroke", "#FF0000")
    .attr("marker-end", () => {
      if (parseInt(targetEdge.dataset.type) === EdgeType.regexConnection) {
        return `url(#arrow-${EdgeType[EdgeType.selectedEdge]})`;
      }
    });
}

export default NetworkGraph;
