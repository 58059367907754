import {
  Accordion,
  AccordionItem,
  Button,
  List,
  ListItem,
} from "@appkit4/react-components";
import { useState } from "react";
import { CourtCaseType, ListData } from "../../@types/general";
import AddConnectionModal from "./AddConnectionModal";

type CourtCaseSectionProps = {
  courtCase: CourtCaseType;
};

function CourtCaseCard({ courtCase }: CourtCaseSectionProps) {
  const [selectedId, setSelectedId] = useState<string>("");
  const [isAddConnectionModalVisible, setAddConnectionModalVisible] =
    useState<boolean>(false);
  const [activeAccordionItems, setActiveAccordionItems] = useState<string[]>(
    []
  );

  function handleAddCourtCaseConnectionClick() {
    setAddConnectionModalVisible(true);
  }

  function onClickAccordion(activeKeys: string[]) {    
    setActiveAccordionItems(activeKeys);
  }

  function onClickAccordionListItem(id: string, accordionItemId: string){
    setSelectedId(id);
    if(activeAccordionItems.includes(accordionItemId)){
        setActiveAccordionItems(activeAccordionItems.filter(v => v !== accordionItemId))
    } else {
        setActiveAccordionItems(activeAccordionItems.concat(accordionItemId))
    }
  }

  function createListData(courtCase: CourtCaseType): ListData[] { 

    const headerMap: Map<string, string> = new Map([
      ["title", "Oikeustapaus"],
      ["instance", "Instanssi"],
      ["law", "Laki"],
      ["date", "Antopäivä"],
      ["keyWords", "Avainsanat"],    
      ["field_of_law", "Oikeudenala"],
      ["section", "Pykälä(t)"]
    ]);
    
    const data: ListData[] = [];
    Object.keys(courtCase).forEach((e, i) => {
      if (Array.from(headerMap.keys()).includes(e)) {
        type ObjectKey = keyof typeof courtCase;
        const key = e as ObjectKey;

        const listEntry: ListData = {
          id: i.toString(),
          primary: headerMap.get(e) ?? e,

          secondary:
            e === "date"
              ? // @ts-ignore: Unreachable code error
                courtCase[key]
                ? // @ts-ignore: Unreachable code error
                  courtCase[key].toLocaleDateString()
                : "-"

              : courtCase[key]
              ? courtCase[key]?.toString()
              : "-",
        };

        data.push(listEntry);
      }
    });
    return data;
  }

  function renderItem(item: any, index: number) {
    if (item.primary === "Avainsanat") {
      return renderKeywords(item);
    }
    return (
      <ListItem
        key={index}
        role="option"
        aria-label={item.primary + "," + item.secondary}
        aria-selected={item.id === selectedId}
        onClick={() => {
          setSelectedId(item.id);
        }}
      >
        <div>
          <span className="primary-text">
            <b>{item.primary}:</b>
          </span>          
          <span className="secondary-text"> {item.secondary}</span>
        </div>
      </ListItem>
    );

    function renderKeywords(item: any): JSX.Element {
      return (
        <ListItem
          key={index}
          role="option"
          aria-label={item.primary + "," + item.secondary}
          aria-selected={item.id === selectedId}
          onClick={() => {       
            onClickAccordionListItem(item.id, "1");
          }}
        >
          <Accordion
            multiple={true}
            onClick={onClickAccordion}
            activeKeys={activeAccordionItems}
          >
            <AccordionItem title="Avainsanat" itemKey="1">
              <span className="secondary-text">{item.secondary}</span>
            </AccordionItem>
          </Accordion>
        </ListItem>
      );
    }
  }

  return (
    <div className="court-case-section-wrapper">
        <ul>

      <List
        itemKey="id"
        bordered={false}
        data={createListData(courtCase)}
        renderItem={renderItem}
        style={{ display: "inline-block" }}
      ></List>
      <div className="metadata-button-group">
        <Button
          kind="primary"
          icon="icon-open-in-new-window-outline"
          role={"link"}
          onClick={() =>
            window.open(
              `m-files://show/${process.env.REACT_APP_VAULT_ID}/0-${courtCase.mFilesId}`
            )
          }
          >
          Näytä M-Filesissa
        </Button>
        <div className="add-and-vote">
          <Button
            kind="secondary"
            icon="icon-circle-plus-outline"
            onClick={handleAddCourtCaseConnectionClick}
          >
            Lisää yhteys
          </Button>
        </div>
      </div>
      <AddConnectionModal
        isVisible={isAddConnectionModalVisible}
        setIsVisible={setAddConnectionModalVisible}
        courtCase={courtCase}
        />
        </ul>
    </div>
  );
}

export default CourtCaseCard;
