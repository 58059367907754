import { makeAutoObservable, runInAction } from "mobx";
import { EdgeType } from "../@types/enums";
import {
  AppliedFilters,
  AppliedFilterValues,
  PropertiesDBType,
  PropertiesType,
} from "../@types/general";
import { isEmptyOrSpaces } from "../assets/helpers/helpers";
import { getData } from "../services/ApiActions";
import { IRootStore, StoreState } from "./RootStore";

export interface IFilterStore {
  updateFilterLogicSection(arg0: string): unknown;
  updateFilterLogicLaw(arg0: string): unknown;
  rootStore: IRootStore;
  appliedFilterValues: AppliedFilterValues;
  appliedFilters: AppliedFilters;
  allFiltersApplied: boolean;
  edgesToDisplay: EdgeType[];
  courtCaseSearch: string | undefined;
  courtCaseProperties: PropertiesType;
  getAllProperties: () => void;
  setEdgesToDisplay: (values: number[]) => void;
  setAppliedFilters: (field: string, value: boolean) => void;
  applyAllFilters: (value: boolean, clearSelections: boolean) => void;
  setAppliedFilterValues: (filter: string, value: any) => void;
  setCourtCaseSearchValue: (value: string | undefined) => void;
  getValidSections: () => string[];
  setAllProperties(properties: PropertiesDBType): void;
  sortValuesBySelection(
    filterType: "instance" | "law" | "section" | "fieldsOfLaw" | "keywords"
  ): void;
  readFilterParams(): void;
  filterLogicLaw : string;
  filterLogicSection : string;
}

class FilterStore implements IFilterStore {
  filterLogicLaw: string = "OR";
  filterLogicSection : string = "OR";
  rootStore: IRootStore;
  appliedFilterValues: AppliedFilterValues;
  appliedFilters: AppliedFilters;
  allFiltersApplied: boolean;
  edgesToDisplay: EdgeType[];
  courtCaseSearch: string | undefined;
  courtCaseProperties: PropertiesType = {
    titles: [],
    fieldsOfLaw: [],
    law: [],
    instance: [],
    keywords: [],
    section: [],
  };

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    this.appliedFilterValues = {};
    this.appliedFilters = {
      fieldsOfLaw: false,
      law: false,
      instance: false,
      decision_date: false,
      keywords: false,
      section: false,
    };
    this.edgesToDisplay = [EdgeType.regexConnection, EdgeType.manualConnection];
    this.courtCaseSearch = undefined;
    this.allFiltersApplied = false;
    this.readFilterParams();
  }

  sortValuesBySelection(
    filterType: "instance" | "law" | "section" | "fieldsOfLaw" | "keywords"
  ): void {
    runInAction(() => {
      const values = this.courtCaseProperties[filterType];
      const valuesSortedBySelection = values.sort((a, b) => {
        if (this.appliedFilterValues[filterType]?.includes(a)) return -1;
        if (this.appliedFilterValues[filterType]?.includes(b)) return 1;
        return a.localeCompare(b, "fi", { numeric: true });
      });
      this.courtCaseProperties = {
        ...this.courtCaseProperties,
        [filterType]: valuesSortedBySelection,
      };
    });
  }

  setEdgesToDisplay(values: number[]) {
    runInAction(() => {
      this.edgesToDisplay = values;
      this.rootStore.courtCaseStore.renderGraph();
    });
  }

  setAppliedFilters(field: string, value: boolean) {    
    runInAction(() => {
      this.appliedFilters = { ...this.appliedFilters, [field]: value };
      if(!value){
        this.allFiltersApplied = false;
      }
    });
    this.setFilterParams(this.appliedFilters, this.appliedFilterValues);
  }

  updateFilterLogicLaw(value: string) {
    this.filterLogicLaw = value;
  }

  updateFilterLogicSection(value: string) {
    this.filterLogicSection = value;
  }
  applyAllFilters(value: boolean, clearSelections: boolean) {
    if (clearSelections === true) {
      this.appliedFilterValues = {};
    }

    runInAction(() => {
      this.allFiltersApplied = value;

      this.appliedFilters = {
        fieldsOfLaw:
          this.appliedFilterValues["fieldsOfLaw"] &&
          this.appliedFilterValues["fieldsOfLaw"].length > 0
            ? value
            : false,
        law:
          this.appliedFilterValues["law"] &&
          this.appliedFilterValues["law"].length > 0
            ? value
            : false,
        instance:
          this.appliedFilterValues["instance"] &&
          this.appliedFilterValues["instance"].length > 0
            ? value
            : false,
        decision_date:
          this.appliedFilterValues["decision_date"] &&
          this.appliedFilterValues["decision_date"].length > 0
            ? value
            : false,
        keywords:
          this.appliedFilterValues["keywords"] &&
          this.appliedFilterValues["keywords"].length > 0
            ? value
            : false,
        section:
          this.appliedFilterValues["section"] &&
          this.appliedFilterValues["section"].length > 0
            ? value
            : false,
      };
      this.setFilterParams(this.appliedFilters, this.appliedFilterValues);
    });
    if (!this.courtCaseSearch) {
      this.rootStore.courtCaseStore.getFilterCourtCasesAndRelations();
    }
  }

  setAppliedFilterValues(filter: string, value: any) {
    const dateTypes = ["startDate", "endDate"];
    const isDate: boolean = dateTypes.includes(filter);

    runInAction(() => {
      if (isDate) {
        const dateTypeIndex: number = dateTypes.findIndex((v) => v === filter);
        if (dateTypeIndex >= 0) {
          let currentDateValue = this.appliedFilterValues.decision_date;
          if (!currentDateValue) {
            currentDateValue = [];
          }
          const dateFormatPattern =
            /^(?:19|20)\d{2}-(?:0[1-9]|1[012])-(?:0[1-9]|[12][0-9]|3[01])$/;

          currentDateValue[dateTypeIndex] =
            value.match(dateFormatPattern) ?? undefined;

          this.appliedFilterValues = {
            ...this.appliedFilterValues,
            decision_date: currentDateValue,
          };
        }
      } else {
        this.appliedFilterValues = {
          ...this.appliedFilterValues,
          [filter]: value,
        };
      }

      this.setFilterParams(this.appliedFilters, this.appliedFilterValues);

      if (this.allFiltersApplied) {
        this.appliedFilters = {
          ...this.appliedFilters,
          [filter]: true,
        };
      }
    });
  }

  setFilterParams(
    appliedFilters: AppliedFilters,
    appliedFilterValues: AppliedFilterValues
  ) {
    const params = new URLSearchParams(window.location.search);
    const appliedFilterKeys = Object.keys(appliedFilters) as
      | (keyof typeof appliedFilters)[]
      | (keyof typeof appliedFilterValues)[];
    appliedFilterKeys.forEach((key) => {
      if (appliedFilters[key]) {
        const value = appliedFilterValues[key];

        if (value && value.length > 0) {
          params.set(key, value.join(";"));
        } else {
          params.delete(key);
        }
      } else {
        params.delete(key);
      }
    });

  }

  readFilterParams() {
    const params = new URLSearchParams(window.location.search);
    const appliedFilterValuesFromQueryParams: AppliedFilterValues = {};
    const appliedFiltersFromQueryParams: AppliedFilters = {
      fieldsOfLaw: false,
      law: false,
      instance: false,
      decision_date: false,
      keywords: false,
      section: false,
    };

    params.forEach((value: string, key: string) => {
      if (key in appliedFiltersFromQueryParams) {
        const typedKey = key as
          | keyof typeof this.appliedFilterValues
          | keyof typeof this.appliedFilters;
        const valuesAsArray = value.split(";");
        appliedFilterValuesFromQueryParams[typedKey] = valuesAsArray;
        appliedFiltersFromQueryParams[typedKey] = true;
      }
    });

    this.appliedFilterValues = appliedFilterValuesFromQueryParams;
    this.appliedFilters = appliedFiltersFromQueryParams;
  }

  async getAllProperties() {
    this.rootStore.storeState = StoreState.LOADING;

    const {response, isError} = this.rootStore.responseHandler(
      await getData<PropertiesDBType>(`graph/properties`)
    );

    runInAction(() => {
      if (response && !isError) {
        this.courtCaseProperties = {
          titles: response.titles.sort(),
          fieldsOfLaw: response.fields_of_law.sort(),
          law: response.laws.sort(),
          instance: response.instances.sort(),
          keywords: Array.from(new Set(response.keywords)).sort(),
          section: response.sections.sort(),
        };
        this.rootStore.storeState = StoreState.READY;
      }
    });
  }

  async setAllProperties(properties: PropertiesDBType) {
    runInAction(() => {
      this.courtCaseProperties = {
        titles: properties.titles.sort(),
        fieldsOfLaw: properties.fields_of_law.sort(),
        law: Array.from(new Set(properties.laws)).sort(),
        instance: properties.instances.sort(),
        keywords: Array.from(new Set(properties.keywords)).sort(),
        section: Array.from(new Set(properties.sections)).sort(),
      };
      this.rootStore.storeState = StoreState.READY;
    });
  }

  setCourtCaseSearchValue(value: string | undefined) {
    runInAction(() => {
      this.courtCaseSearch = value;
    });
  }

  getValidSections() {
    if (
      this.appliedFilterValues.law &&
      this.appliedFilterValues.law.length < 1
    ) {
      return [];
    }
    const pattern = /\(\d{1,4}\/(?:19|20)\d{2}\)/;

    const lawIdentifiers: string[] = this.appliedFilterValues.law
      ? this.appliedFilterValues.law
          .map((v) => {
            const match = v.match(pattern);
            if (match) {
              return match.toString();
            }
            return "";
          })
          .filter((v) => !isEmptyOrSpaces(v))
      : [];

    const filteredSections = this.courtCaseProperties.section.filter((v) => {
      const match = v.match(pattern);

      return match ? lawIdentifiers.includes(match.toString()) : false;
    });
    return filteredSections.sort((a, b) =>
      a.localeCompare(b, "fi", { numeric: true })
    );
  }
}

export default FilterStore;
