import { Select } from "@appkit4/react-components";
import { Switch } from "@appkit4/react-components/switch";
import { useContext, useEffect, useState } from "react";
import { areArraysEqual } from "../../assets/helpers/helpers";
import "./Filter.scss";
import { IRootStore } from "../../store/RootStore";
import { StoreContext } from "../../App";

type FilteringLogic = {
    name: string;
    applyFilter: boolean;
    handleApplyFilter: (field: string, value: boolean) => void;

};

const FilteringLogic = ({
    name,
    applyFilter,
    handleApplyFilter

}: FilteringLogic) => {


    const filterStore = useContext<IRootStore>(StoreContext).filterStore;
    
    const [filterLogicLaw, setFilterLogicLaw] = useState("OR");
    const [isLawSwitchOR, setIsLawSwitchOR] = useState<boolean>(true);
    
    const [filterLogicSection, setFilterLogicSection] = useState("OR");
    const [isSectionSwitchOR, setIsSectionSwitchOR] = useState<boolean>(true);
    

    const [isChecked, setIsChecked] = useState(true);

      const handleSwitchChange = (checked: boolean) => {
        setIsChecked(checked)
        if(name == "Pykälä"){
             setIsSectionSwitchOR(!isSectionSwitchOR)
             setFilterLogicSection(filterLogicSection === "OR" ? "AND" : "OR");
             filterStore.updateFilterLogicSection(filterLogicSection === "OR" ? "AND" : "OR");
             if(applyFilter){
                handleApplyFilter(name, true)
             }
        }
        if(name == "Laki"){
            filterStore.filterLogicLaw = filterLogicLaw
            setIsLawSwitchOR(!isLawSwitchOR)
            setFilterLogicLaw(filterLogicLaw === "OR" ? "AND" : "OR");
            filterStore.updateFilterLogicLaw(filterLogicLaw === "OR" ? "AND" : "OR"); // Update filterLogicLaw in FilterStore

            if(applyFilter){
                handleApplyFilter(name, true)
             }
       }
      };
  
    const renderSwitch = () => {
        return (
            <Switch
            checked= {isChecked}
            id="filterLogicSwitch"
            onChange={(checked) =>{
                handleSwitchChange(checked)
            }}
            />
        )
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px', marginBottom: "4px", fontWeight: 'bold', marginRight:'4px' }}>
            <span style={{ marginRight: '4px', fontSize: '10px' }}>AND</span>
            {renderSwitch()}
            <span style={{ marginLeft: '4px', fontSize: '10px' }}>OR</span>
        </div>
    );
};


export default FilteringLogic;