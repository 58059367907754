import { makeAutoObservable } from "mobx";
import { MainContentView, NotificationType } from "../@types/enums";
import { Notification, UserType } from "../@types/general";
import CourtCaseStore, { ICourtCaseStore } from "./CourtCaseStore";
import FilterStore, { IFilterStore } from "./FilterStore";
import { enqueueSnackbar } from "notistack";
import { getData, setBearerToken } from "../services/ApiActions";

export enum StoreState {
  READY = 0,
  LOADING = 1,
  ERROR = 2,
}

export interface IRootStore {
  storeState: StoreState;
  notification: undefined | Notification;
  courtCaseStore: ICourtCaseStore;
  filterStore: IFilterStore;
  userInfo: UserType | undefined;
  idToken: string | undefined;
  responseHandler: <T>(response: T) => { isError: boolean; response: T };
  getUserInitials(): string;
  getUserInfo(): Promise<UserType | undefined>;
  showUserNotification(notification: Notification): void;
  setActiveView(view: MainContentView | undefined): void;
  getActiveView(): MainContentView;
}

class RootStore implements IRootStore {
  storeState: StoreState;
  filterStore: FilterStore;
  notification: Notification | undefined;
  courtCaseStore: ICourtCaseStore;
  activeView: MainContentView;
  userInfo: UserType | undefined;
  idToken: string | undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.storeState = StoreState.LOADING;
    this.activeView = MainContentView.graph;
    this.filterStore = new FilterStore(this);
    this.courtCaseStore = new CourtCaseStore(this);
    this.notification = undefined;
  }
  getUserInitials(): string {
    if (this.userInfo) {
      const firstGivenNameLetter: string = this.userInfo.given_name[0];
      const firstFamilyNameLetter: string = this.userInfo.family_name[0];
      return firstGivenNameLetter + firstFamilyNameLetter;
    }
    return "-";
  }

  async getUserInfo(): Promise<UserType | undefined> {
    const {isError, response} = this.responseHandler(
      await getData<UserType>("/authorization/userinfo")
    );

    if (!isError && response) {
      setBearerToken(response.id_token);
      this.idToken = response.id_token;
      return (this.userInfo = response);
    }
    throw Error("Unable to get user info");
  }

  setActiveView(view: MainContentView | undefined): void {
    if (view) {
      this.activeView = view;
    } else {
      this.activeView = MainContentView.graph;
    }
  }

  getActiveView(): MainContentView {
    return this.activeView;
  }

  responseHandler<T>(response: T): { isError: boolean; response: T } {
    let isError = false;     

    if (!response) {     
        isError = true;
        this.notification = {
          message: "Odottamaton virhe. Sulje selain ja yritä uudelleen",
          type: NotificationType.WARNING,
          persist: true,}       
        this.showUserNotification(this.notification);
      
      this.storeState = StoreState.ERROR;
      //@ts-ignore if the response is other than expected e.g. error response
    } else if (response.isError) {
      isError = true;
      //@ts-ignore if the response is other than expected e.g. error response
      if(response.statusCode !== 401){
        isError = true;
        this.notification = {
          message: "Odottamaton virhe. Sulje selain ja yritä uudelleen",
          type: NotificationType.WARNING,
          persist: true,
        };
        
        this.storeState = StoreState.ERROR;
        this.showUserNotification(this.notification);
      } 
    }
   
    return { isError: isError, response: response };
  }

  showUserNotification(notification: Notification) {
    enqueueSnackbar(notification.message, {
      variant: "userNotification",
      persist: notification.persist,
      notificationType: notification.type,
    });
  }
}

export default RootStore;
