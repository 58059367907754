import { useCallback, useContext } from "react";
import "./Filter.scss";
import SearchByDate from "./SearchByDate";
import SearchMultipleField from "./SearchMultipleField";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Switch,
  Tooltip,
} from "@appkit4/react-components";
import { IRootStore } from "../../store/RootStore";
import { StoreContext } from "../../App";
import { observer } from "mobx-react-lite";
import GraphLegend from "./GraphLegend";
import { MainContentView } from "../../@types/enums";
import { isEmptyOrSpaces } from "../../assets/helpers/helpers";

const Filter = observer(() => {
  const courtCaseStore = useContext<IRootStore>(StoreContext).courtCaseStore;
  const filterStore = useContext<IRootStore>(StoreContext).filterStore;

  const handleApplyFilter = useCallback(
    (field: string, value: boolean) => {     
      filterStore.setAppliedFilters(field, value);
      
      courtCaseStore.getFilterCourtCasesAndRelations();
    },
    [courtCaseStore, filterStore]
  );

  function handleChangeFilter(filter: string, value: any) {
    filterStore.setAppliedFilterValues(filter, value);
  }

  function getGraphData() {
    courtCaseStore.getFilterCourtCasesAndRelations();
  }

  function getDropdownValues(
    filterType: "instance" | "law" | "section" | "fieldsOfLaw" | "keywords"
  ) {
    const values =
      filterType === "section"
        ? filterStore.getValidSections()
        : filterStore.courtCaseProperties[filterType];
    return values.filter((value) => !isEmptyOrSpaces(value));
  }

  function sortValuesBySelection(filterType: "instance" | "law" | "section" | "fieldsOfLaw" | "keywords"){
    filterStore.sortValuesBySelection(filterType)
  }

  function handleEdgesToDisplayChange(values: any, event: any) {
    if (event.target.className !== "ap-checkbox-container") {
      filterStore.setEdgesToDisplay(values);
    }
  }

  function renderGraphLegend(): JSX.Element {
    if (courtCaseStore.rootStore.getActiveView() === MainContentView.graph) {
      return <GraphLegend />;
    }
    return <></>;
  }

  return (
    <div className="filter-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingRight: "1rem",
        }}
      >
        <h3>Suodattimet</h3>
        <Tooltip content={"Tyhjennä kaikki suodattimet"}>
          <Button
            add
            icon="icon-filter-remove-outline"
            compact
            onClick={() => filterStore.applyAllFilters(false, true)}
          >
            Label
          </Button>
        </Tooltip>
      </div>

      <div className="filter-activate-all-filters">
        <label>Aktivoi kaikki suodattimet</label>
        <Switch
        checked={filterStore.allFiltersApplied}
          onChange={(checked: boolean) =>
            filterStore.applyAllFilters(checked, false)
          }
        />
      </div>
      {renderGraphLegend()}
      <div className="filter-content">
        <SearchMultipleField
          name="instance"
          filterOptions={getDropdownValues("instance")}
          selectedOptions={filterStore.appliedFilterValues.instance ?? []}
          handleApplyFilter={handleApplyFilter}
          getGraphData={getGraphData}
          applyFilter={filterStore.appliedFilters.instance}
          handleValueChanged={handleChangeFilter}
          placeholder="Instanssi"
          sortValuesBySelection={() => sortValuesBySelection("instance")}
        />
        <SearchMultipleField
          name="law"
          filterOptions={getDropdownValues("law")}
          selectedOptions={filterStore.appliedFilterValues.law ?? []}
          handleApplyFilter={handleApplyFilter}
          getGraphData={getGraphData}
          applyFilter={filterStore.appliedFilters.law}
          handleValueChanged={handleChangeFilter}
          placeholder="Laki"
          sortValuesBySelection={() => sortValuesBySelection("law")}
        />
        <SearchMultipleField
          name="section"
          filterOptions={getDropdownValues("section")}
          selectedOptions={filterStore.appliedFilterValues.section ?? []}
          handleApplyFilter={handleApplyFilter}
          getGraphData={getGraphData}
          applyFilter={filterStore.appliedFilters.section}
          handleValueChanged={handleChangeFilter}
          placeholder="Pykälä"
          disabled={
            filterStore.appliedFilterValues.law
              ? !(filterStore.appliedFilterValues.law.length > 0)
              : true
          }
          sortValuesBySelection={() => sortValuesBySelection("section")}
        />
        <SearchMultipleField
          name="fieldsOfLaw"
          filterOptions={getDropdownValues("fieldsOfLaw")}
          selectedOptions={filterStore.appliedFilterValues.fieldsOfLaw ?? []}
          handleApplyFilter={handleApplyFilter}
          getGraphData={getGraphData}
          applyFilter={filterStore.appliedFilters.fieldsOfLaw}
          handleValueChanged={handleChangeFilter}
          placeholder="Oikeudenala"
          sortValuesBySelection={() => sortValuesBySelection("fieldsOfLaw")}
        />
        <SearchMultipleField
          name="keywords"
          filterOptions={getDropdownValues("keywords")}
          selectedOptions={filterStore.appliedFilterValues.keywords ?? []}
          handleApplyFilter={handleApplyFilter}
          getGraphData={getGraphData}
          applyFilter={filterStore.appliedFilters.keywords}
          handleValueChanged={handleChangeFilter}
          placeholder="Avainsana"
          sortValuesBySelection={() => sortValuesBySelection("keywords")}
        />
        <SearchByDate
          name="decision_date"
          handleApplyFilter={handleApplyFilter}
          applyFilter={filterStore.appliedFilters.decision_date}
          handleValueChanged={handleChangeFilter}
          selectedValues={filterStore.appliedFilterValues.decision_date}
          minDate={
            filterStore.appliedFilterValues.decision_date
              ? filterStore.appliedFilterValues.decision_date[0]
              : undefined
          }
          maxDate={
            filterStore.appliedFilterValues.decision_date
              ? filterStore.appliedFilterValues.decision_date[1]
              : undefined
          }
        />
        <CheckboxGroup
          value={filterStore.edgesToDisplay}
          name="edges"
          onChange={handleEdgesToDisplayChange}
        >
          <Checkbox value={2}>Päätöstekstistä tunnistetut viittaukset</Checkbox>
          <Checkbox value={3}>Käyttäjien lisäämät viittaukset</Checkbox>
        </CheckboxGroup>
      </div>
    </div>
  );
});

export default Filter;
