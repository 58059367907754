export enum NotificationType {
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success' 
 }

 export enum TokenStatus {
    OK = 0,
    EXPIRED,
    NO_TOKEN
}

export enum Instance {
    KorkeinHallintoOikeus = 0,
    Keskusverolautakunta,
    HallintoOikeus,
    EUTuomioistuin,
    Verohallinto,
    Oikaisulautakunta,
    Muu,
    undefined
}

export enum Law {
    Hallintolaki = 0,
    Ajoneuvoverolaki,
    Verolaki,
    Kovalaki
}

export enum CourtType {
    Henkilövero = 0,
    Ajoneuvovero,
    Kiinteistovero,
    Päävero
}

export enum Keywords {
    Ajoneuvo = 0,
    Yleisvero,
    EnKeksiEnempää,
    JotainKeksittyä
}

export enum EdgeType {
    referencedIn = 1,
    regexConnection,
    manualConnection,
    selectedEdge,
    undefined
}

export enum MainContentView {
    graph = 0,
    list = 1
}

