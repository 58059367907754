import { Notification } from "@appkit4/react-components/notification";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { forwardRef } from "react";
import { NotificationType } from "../@types/enums";

declare module "notistack" {
  interface VariantOverrides {
    userNotification: {
      notificationType: NotificationType;
    };
  }
}

interface ReportCompleteProps extends CustomContentProps {
  notificationType: NotificationType;
}

const SnackNotification = forwardRef<HTMLDivElement, ReportCompleteProps>(
  (props, ref) => {
    const { closeSnackbar } = useSnackbar();

    const { id, message, notificationType }: ReportCompleteProps = props;

    function handleCloseSnackbar() {
      closeSnackbar(id);
    }

    const notification = (
      <Notification
        title=""
        message={message}
        status={notificationType}
        onClose={() => handleCloseSnackbar()}
      />
    );
    return <SnackbarContent ref={ref}>{notification}</SnackbarContent>;
  }
);

export default SnackNotification;