import { Select } from "@appkit4/react-components";
import { Pagination } from "@appkit4/react-components/pagination";
import { Table, Column } from "@appkit4/react-components/table";
import { Loading } from "@appkit4/react-components/loading";
import { StoreState } from "../../store/RootStore";
import { useContext, useEffect, useState } from "react";
import {
  getCourtCaseReferences,
  getCourtCasesRefTo,
} from "../../assets/helpers/helpers";
import { ListItemType } from "../../@types/general";
import "./ListView.scss";
import "../metadataCard/MetadataCard.scss";
import { StoreContext } from "../../App";
import { observer } from "mobx-react-lite";

const ListView = observer(() => {
  const [originalData, setOriginalData] = useState<ListItemType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [currentPageItems, setCurrentPageItems] = useState<ListItemType[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const store = useContext(StoreContext).courtCaseStore;
  useEffect(() => {
    const items: ListItemType[] = store.activeCourtCases.map((n) => {
      return {
        id: n.id,
        label: n.label,
        instance: n.instance,
        law: n.law,
        date: n.date,
        keyWords: n.keyWords,
        referencesTo: getCourtCasesRefTo(n.id, store.getGraphData()),
        referencesToSelected: undefined,
        referencesFrom: getCourtCaseReferences(n.id, store.getGraphData()),
        referencesFromSelected: undefined,
        directNodes: n.directNodes,
        
      };
      
    });
    setPage(1);
    setOriginalData(items.filter(item => item.directNodes));
  }, [store]);

  useEffect(() => {
    function getCurrentPageItems() {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex =
        page * itemsPerPage > originalData.length 
          ? originalData.length 
          : page * itemsPerPage;
      return originalData.slice(startIndex, endIndex);
    }
    setCurrentPageItems(getCurrentPageItems());
  }, [originalData, page, itemsPerPage]);

  function handlePageChange(number: number) {
    setPage(number);
  }


  const renderLoader = () => {
    if (store.rootStore.storeState === StoreState.LOADING) {
      return (
        <div id="loader-wrapper">
          <div id="loader">
            <Loading
              loadingType="circular"
              circularWidth="36px"
              indeterminate={true}
              compact={false}
            ></Loading>
          </div>
        </div>
      );
    }
  };

  function handleUpdateDropdown(value: any) {
    // displays the metadatacard
    if (value) {
      const clickedCourtCase = store.activeCourtCases.find(
        (n) => n.id === value
      );
      if (clickedCourtCase) {
        store.setSelectedCourtCase(clickedCourtCase);
      }
    }
  }

  function referencedBy(row: any, field: string) {

    return (
      <Select
        data={row.referencesTo}
        className="ListViewSelect"
        value=""
        valueKey={"id"}
        placeholder={row.referencesTo.length}
        disabled={row.referencesTo.length === 0}
        searchable={false}
        noResultFound="Ei löytyneitä viittauksia"
        onSelect={(vals) => handleUpdateDropdown(vals)}
      ></Select>
    );
  }

  function references(row: any, field: string) {
    return (
      <Select
        className="ListViewSelect"
        data={row.referencesFrom}
        valueKey={"id"}
        value=""
        placeholder={row.referencesFrom.length}
        disabled={row.referencesFrom.length === 0}
        searchable={false}
        noResultFound="Ei löytyneitä viittauksia"
        onSelect={(vals) => handleUpdateDropdown(vals)}
      ></Select>
    );
  }

  function handleClickOnRow(event: MouseEvent, data: any) {
    const validElements: string[] = ["td", "span"];
    const element: HTMLElement = event.target as HTMLElement;

    if (validElements.includes(element.localName)) {
      element.tabIndex = 1;
      element.focus();
      const clickedCourtCase = store.activeCourtCases.find(
        (n) => n.id === data.id
      );

      if (clickedCourtCase) {
        store.setSelectedCourtCase(clickedCourtCase);
      }
    }
  }

  return (
    <>
      <div className="list-view-container">
        <div className="list-view">
          <Table
            originalData={currentPageItems}
            hasTitle
            striped
            condensed
            onRowClick={(event: MouseEvent, index: number, data: any) =>
              handleClickOnRow(event, data)
            }
          >
            <Column field="label">Päätös</Column>
            <Column field="instance">Instanssi</Column>
            <Column field="law">Laki</Column>

            <Column
              field="referencesTo"
              renderCell={(row: any, field: string) => referencedBy(row, field)}
            >
              Viittaukset tähän päätökseen
            </Column>

            <Column
              field="id"
              renderCell={(row: any, field: string) => references(row, field)}
            >
              Päätöksessä mainitut oikeustapaukset
            </Column>
          </Table>
          {renderLoader()}
          <div></div>
          <div></div>
        </div>
        <div className="list-pagination">
          <div>
            <Select
              dropdownAlwaysDown={false}
              data={[
                { value: 5, label: 5 },
                { value: 10, label: 10 },
                { value: 15, label: 15 },
                { value: 20, label: 20 },
                { value: 25, label: 25 },
              ]}
              defaultValue={itemsPerPage}
              placeholder=""
              searchable={false}
              onSelect={(vals) => setItemsPerPage(Number(vals))}
            ></Select>
          </div>

          <Pagination
            defaultCurrent={page}
            total={Math.ceil(originalData.length / itemsPerPage)}
            onPageChange={(number: number) => handlePageChange(number)}
            toPreviousPage={"Previous"}
            toNextPage={"Next"}
          ></Pagination>
        </div>
      </div>
    </>
  );
});

export default ListView;
