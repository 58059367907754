import { Instance } from "../../@types/enums";
import {
  AppliedFilterOptions,
  CommentType,
  CourtCaseType,
  GraphData,
  commentListItem,
} from "../../@types/general";

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getFilterOptions(
  courtCases: CourtCaseType[]
): AppliedFilterOptions {
  const defaultSearchOptions: AppliedFilterOptions = {
    courtCaseName: courtCases.map((x, i) => {
      return { key: i, label: x.label };
    }),
    fieldsOfLaw: courtCases
      .map((x) => x.type)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((x, i) => {
        return { key: i, label: x };
      }),
    law: courtCases
      .map((x) => x.law)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((x, i) => {
        return { key: i, label: x };
      }),
    instance: courtCases
      .map((x) => x.instance)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((x, i) => {
        return { key: i, label: x };
      }),
    decision_date: courtCases
      .map((x) => x.date)
      .filter((value, index, self) => self.indexOf(value) === index),
    keywords: [],

  };

  return defaultSearchOptions;
}




export function getCourtCasesRefTo(
  parentId: string,
  GraphData: GraphData
): CourtCaseType[] {
  const relationIds: string[] = GraphData.links
    .filter((r) => r.source === parentId)
    .map((c) => c.target.toString());
  return GraphData.nodes.filter((c) => relationIds.includes(c.id));
}

export function getCourtCaseReferences(
  parentId: string,
  GraphData: GraphData
): CourtCaseType[] {
  const relationIds: string[] = GraphData.links
    .filter((r) => r.target === parentId)
    .map((c) => c.source.toString());
  return GraphData.nodes.filter((c) => relationIds.includes(c.id));
}

export function getInstanceEnum(instance: string): Instance {  
  switch (instance) {
    case "Korkein hallinto-oikeus":
      return Instance.KorkeinHallintoOikeus;
    case "Keskusverolautakunta":
      return Instance.Keskusverolautakunta;
    case "Hallinto-oikeus":
      return Instance.HallintoOikeus;
    case "EU-tuomioistuin":
      return Instance.EUTuomioistuin
    case "Verohallinto":
      return Instance.Verohallinto
    case "Oikaisulautakunta":
      return Instance.Oikaisulautakunta
    default:      
      return Instance.undefined;
  }
}

export function isEmptyOrSpaces(str:string){
  return str === null || str.match(/^ *$/) !== null;
}

export function areArraysEqual(a: any[], b:any[]) {
  a = Array.isArray(a) ? a : [];
  b = Array.isArray(b) ? b : [];
  return a.length === b.length && a.every(el => b.includes(el));
}


export function getEnumNonNumberKeys(enumToParse:object):string[]{
  const allKeys = Object.keys(enumToParse);
  const textKeys = allKeys.filter(v => !(parseInt(v) >= 0))
  return textKeys
}

export function getEnumNumberKeys(enumToParse:object):number[]{
  const allKeys = Object.keys(enumToParse);
  const numberKeys = allKeys.filter(v => (parseInt(v) >= 0)).map(v => parseInt(v))
  return numberKeys
}

export function convertCommentToCommentListItem(comments: CommentType[]): commentListItem[]{
  return comments.map(comment => ({
      shortName: getShortName(comment.creatingUserFirstName, comment.creationUserLastName),
      shortNameBgColor: "#415385",
      fullName: `${comment.creatingUserFirstName} ${comment.creationUserLastName}`,
      commentsTime: formatDateTime(comment.date),
      images: undefined,
      likesCount: 0,
      liked: false,
      commentsCount: undefined,
      commentsContent: comment.comment
  }))


  function getShortName(firstName: string, lastName: string): string{
      let shortName = "";
      try{
          shortName = firstName[0] + lastName[0]
      }
      catch{
          shortName = "-"
      }
      return shortName
  }

  function formatDateTime(unformattedDateTime: string):string{
      const dateTime = new Date(Date.parse(unformattedDateTime));
      const date = dateTime.getDate()
      const month = dateTime.getMonth() + 1
      const year = dateTime.getFullYear()
      const hours = ("0" + dateTime.getHours()).slice(-2);
      const minutes = ("0" + dateTime.getMinutes()).slice(-2);
      const formattedDateTime = `${date}.${month}.${year} ${hours}:${minutes}`
      return formattedDateTime
  }
}