import { Select } from "@appkit4/react-components";
import { Switch } from "@appkit4/react-components/switch";
import { useEffect, useState } from "react";
import { areArraysEqual } from "../../assets/helpers/helpers";
import "./Filter.scss";
import FilteringLogic from "./FilteringLogic";

type SearchMultipleFieldProps = {
  name: string;
  filterOptions: string[];
  placeholder: string;
  applyFilter: boolean;
  handleApplyFilter: (field: string, value: boolean) => void;
  handleValueChanged: (filter: string, value: any) => void;
  selectedOptions: string[];
  getGraphData: () => void;
  disabled?: boolean;
  sortValuesBySelection(): void
};

const SearchMultipleField = ({
  name,
  filterOptions,
  placeholder,
  applyFilter,
  handleApplyFilter,
  handleValueChanged,
  selectedOptions,
  getGraphData,
  disabled = false,
  sortValuesBySelection
}: SearchMultipleFieldProps) => {
  const [isSwitchDisabled, setIsSwitchDisabled] = useState<boolean>(false);

  const [valuesOnOpen, setValuesOnOpen] = useState<string[]>(selectedOptions);

  useEffect(() => {
    const isDisabled = !(selectedOptions?.length > 0);

    setIsSwitchDisabled(isDisabled);
  }, [selectedOptions?.length]);

  const renderSwitch = () => {
    return (
      <Switch
        disabled={isSwitchDisabled}
        checked={applyFilter && selectedOptions?.length > 0}
        onChange={(e) => handleApplyFilter(name, e)}
      />
    );
  };

  const handleOnSelect = (items: any) => {
    handleValueChanged(name, items);
  };

  const handleDropdownOpenAndClose = (open: boolean) => {
    if (!open && !areArraysEqual(valuesOnOpen, selectedOptions)) {
      getGraphData();
    } else if (open) {
      setValuesOnOpen(selectedOptions);
    }   
    sortValuesBySelection()

  };
  const renderFilteringLogicSwitch = () => {
    if (placeholder === "Laki" || placeholder === "Pykälä") 
      {
        return (
          <FilteringLogic
            name={placeholder}
            applyFilter={applyFilter && selectedOptions?.length > 0}
            handleApplyFilter={handleApplyFilter}
          />
        )
      }
  }
  return (
    <div className="filter-section">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <h4>{placeholder} </h4>
          {renderFilteringLogicSwitch()}
        </div>
        <Select
          className="filter-select"
          data={filterOptions}
          value={selectedOptions}
          valueKey="label"
          labelKey="label"
          placeholder={placeholder}
          searchable
          onSelect={(e) => handleOnSelect(e)}
          multiple
          showSearchOnToggle
          showSelectAll={false}
          onVisibleChange={(open) => handleDropdownOpenAndClose(open)}
          disabled={disabled}
          dropdownRenderMode={"portal"}
        ></Select>
      </div>
      {renderSwitch()}
    </div>
  );
};

export default SearchMultipleField;
