import { AxiosError, InternalAxiosRequestConfig } from "axios";
import axios from 'axios';
export const STORAGE_KEYS = {
    // Key for id_token
    TOKEN: 'id_token',
    // Key for denied_url
    DENIED_URL: 'denied_url',
};


let bearerToken:string | undefined;

export function setBearerToken(token:string){
  bearerToken = token;
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_Backend,
    timeout: 120000,
    withCredentials: true 
});
instance.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    if (request.headers)
        request.headers['Authorization'] = `Bearer ${bearerToken}`;
    return request;
});

export const getData = <T>(endpoint: string): Promise<T> =>  {  
    return instance.get(endpoint)
      .then((response: any) => response.data)
      .catch((error: AxiosError) => {
        return ({isError: true, statusCode: error.response?.status,...error, result: []})
      })
  }
  
  export const postData = <T, U>(endpoint: string, data: T): Promise<U> => {
    return instance.post(endpoint, data)
      .then((response: any) => response.data)
      .catch((error: AxiosError) => {
        return ({isError: true, ...error})
      })
  }
  
  export const putData = <T, U>(endpoint: string, data: T): Promise<U> => {
    return instance.put(endpoint, data)
      .then((response: any) => response.data)
      .catch((error: AxiosError) => {
        return ({isError: true, ...error})
      })
  }
  export const deleteData = <T>(endpoint: string): Promise<T> => {
    return instance.delete(endpoint)
      .then((response: any) => response.data)
      .catch((error: AxiosError) => {
        return ({isError: true, ...error})
      })
  }