import React, { useEffect } from 'react';
import * as d3 from 'd3';

export const useD3 = (renderFunction:Function, dependencies: any[]) => {
    const ref = React.useRef();

    
    useEffect(() => {
        if (ref.current) {
            
            renderFunction(d3.select(ref.current), ref.current);
            
        }
        return () => { };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
    return ref;
}