import { Button, Modal, Select, TextArea } from "@appkit4/react-components";
import { useContext, useState } from "react";
import { CourtCaseType } from "../../@types/general";
import { StoreContext } from "../../App";
import { isEmptyOrSpaces } from "../../assets/helpers/helpers";
import { IRootStore } from "../../store/RootStore";

type AddConnectionModalProps = {
  isVisible: boolean;
  setIsVisible(showModal: boolean): void;
  courtCase: CourtCaseType;
};

type DropdownValueAndLabeltype = {
  value: string,
  label: string
}

function AddConnectionModal({
  isVisible,
  setIsVisible,
  courtCase,
}: AddConnectionModalProps) {
  const [dropdownValue, setDropdownValue] = useState<string>("");

  const [connectionCommentText, setConnectionCommentText] =
    useState<string>("");

  const courtCaseStore = useContext<IRootStore>(StoreContext).courtCaseStore;

  function getAllCourtCaseNamesForSelectData() {
    return courtCaseStore.allCourtCases.reduce((CourtCaseTypeArray: DropdownValueAndLabeltype[], currentCourtCase) => {
      if(courtCase.id !== currentCourtCase.id){
        CourtCaseTypeArray.push({
          value: currentCourtCase.id,
          label: currentCourtCase.title,
        });
      }
      return CourtCaseTypeArray
    },[]);
  }

  function handleOnClose() {
    setIsVisible(false);
    setConnectionCommentText("");
    setDropdownValue("");
  }

  function handleAddConnectionClicked(){
    courtCaseStore.addNewCourtCaseRelation(courtCase.id, dropdownValue, connectionCommentText)
    handleOnClose()
  }

  function isAddConnectionButtonDisabled(){
    return isEmptyOrSpaces(dropdownValue) || isEmptyOrSpaces(connectionCommentText)
  }

  function renderModalContent() {
    return (
      <div className="connection-selection">
        <Select
          placeholder="Valitse oikeustapaus johon yhdistetään"
          data={getAllCourtCaseNamesForSelectData()}
          value={dropdownValue}
          searchable={true}
          onSelect={(value) => setDropdownValue(value.toString())}
          style={{ width: "100%", marginBottom: "1rem" }}
        />

        <TextArea
          title={"Kuvaa yhteyttä"}
          maxLength={420}
          value={connectionCommentText}
          onChange={setConnectionCommentText}
          style={{ width: "100%" }}
          disabled={isEmptyOrSpaces(dropdownValue)}
        ></TextArea>
      </div>
    );
  }

  const header = (
    <h3>
      Lisää yhteys oikeustapaukseen: <br /> {courtCase.title}
    </h3>
  );

  const footer = (
    <div className="add-connection-footer">
      <Button kind="negative" onClick={handleOnClose}>
        Peruuta
      </Button>
      <Button onClick={handleAddConnectionClicked} disabled={isAddConnectionButtonDisabled()}>Lisää yhteys</Button>
    </div>
  );

  return (
    <Modal
      className="add-connection-modal"
      visible={isVisible}
      ariaLabel={"Lisää yhteys"}
      onCancel={() => handleOnClose()}
      modalStyle={{ width: "33.75rem" }}
      header={header}
      footer={footer}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default AddConnectionModal;
